import React from 'react';
import './Footer.sass'
import {Link} from "react-router-dom";

import visa from '../../Media/Icons/visa.svg'
import yumoney from '../../Media/Images/yumoney.png'
import mir from '../../Media/Images/Mir_Pay_logo 1.png'
import cripta from '../../Media/Images/cripta.png'
import sbp from '../../Media/Images/sbp.png'
import mastercard from '../../Media/Images/mastercard.png'
import image1 from '../../Media/Images/image1.png'

import {ReactComponent as FooterCheck} from '../../Media/Icons/footer_check.svg'
import {ReactComponent as FooterTerminal} from '../../Media/Icons/footer_terminal.svg'

function Footer() {
    return (
        <footer>
            <div className="footer_container">
                <div className="item">
                    <p>ООО «НКЦ»</p>

                    <p>ОГРН 1075256005021</p>

                    <p>ИНН 5256069272</p>

                    <p>КПП 525601001</p>

                    <p>Дата регистрации 02.05.2007</p>

                    <p>Нижегородская область, город Нижний Новгород, пр-кт Ленина, 97А</p>
                </div>

                <div className="item">
                    <div className="footer-pay"><img src={visa} alt=""/></div>
                    <div className="footer-pay"><img src={yumoney} alt=""/></div>
                    <div className="footer-pay"><img src={mir} alt=""/></div>

                    <div className="footer-pay"><img src={cripta} alt=""/></div>
                    <div className="footer-pay"><img src={sbp} alt=""/></div>
                    <div className="footer-pay"><img src={mastercard} alt=""/></div>

                    <div className="footer-pay"><img src={image1} alt=""/></div>
                    <div className="footer-pay">
                        <FooterCheck/> <p>Счет на оплату</p>
                    </div>
                    <div className="footer-pay">
                        <FooterTerminal/> <p>Терминал</p>
                    </div>
                </div>

                <div className="item">
                    <p>
                        Согласно федеральному закону "О рынке ценных бумаг" от 22.04.1996N 39-ФЗ на сайте не
                        осуществляется брокерская деятельность и не оказываются услуги Форекс дилинга. На Сайте
                        осуществляется деятельность только по предоставлению консультационных услуг по вопросам
                        финансового посредничества на платной и бесплатной основе. Все материалы на сайте носят
                        исключительно информационный характер и не являются указанием к действию. Представленные
                        данные – это только предположения, основанные на нашем опыте.
                        <br/>
                        Публикуемые результаты торговли
                        добавляются исключительно с целью демонстрации эффективности и не являются заявлением
                        доходности. Прошлые результаты не гарантируют конкретных результатов в будущем. Проведение
                        торговых операций на финансовых рынках с маржинальными финансовыми инструментами имеет высокий
                        уровень риска, поэтому подходит не всем инвесторам. Вы несёте полную ответственность за
                        принятые торговые решения и результат, полученный в ходе работы.
                        <br/>
                        <br/>

                        Сайт не побуждает к заключению сделок с форекс-диллерами, а исключительно публикует цены
                        активов. Но согласно рекламной политике Яндекса, на сайте и в объявлениях необходимо разместить
                        информации о лицензированных форекс-диллерах:
                        <br/>
                        ООО «Альфа-Форекс», лицензия № 045-14070-020000 от 20.12.2018
                        <br/>
                        ООО «ФИНАМ ФОРЕКС», лицензия № 045-13961-020000 от 14.12.2015
                        <br/>
                        ООО «ВТБ Форекс», лицензия № 045-13993-020000 от 01.09.2016
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
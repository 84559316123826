import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../Media/Logo.svg'
import {ReactComponent as MenuArrow} from '../../Media/Icons/menu_arrow_icon.svg'

import {ReactComponent as PhoneIcon} from '../../Media/Icons/phone_icon.svg'
import {ReactComponent as EmailIcon} from '../../Media/Icons/email_icon.svg'
import {ReactComponent as TelegramIcon} from '../../Media/Icons/telegram_icon.svg'

import './Header.sass'
import {Link} from "react-router-dom";

function Header({isOpen, setIsOpen}) {
    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const hamburger = document.getElementById('hamburger')
        if (isOpen) {
            hamburger.classList.add('active')
        } else {
            hamburger.classList.remove('active')
        }
        // console.log(isOpen)
    }, [isOpen]);

    const menuItemToggle = (id) => {
        const menuItemHead = document.getElementById(`menu_item-${id}`).childNodes[0]
        const menuItemBody = document.getElementById(`menu_item-${id}`).childNodes[1]

        if (!menuItemHead.classList.contains('item_head_active') && !menuItemBody.classList.contains('item_body_active')) {
            menuItemHead.classList.add('item_head_active')
            menuItemBody.classList.add('item_body_active')
        } else {
            menuItemHead.classList.remove('item_head_active')
            menuItemBody.classList.remove('item_body_active')
        }
    }

    useEffect(() => {
        const container = document.getElementById('extra_menu_container')
        const header = document.getElementById('header_container')

        if (isOpen) {
            container.style.display = 'flex'
            setTimeout(() => {
                container.classList.remove('extra_menu_container_closed')
                header.classList.add('header_container_open')
            }, 0)
        } else {
            container.classList.add('extra_menu_container_closed')
            header.classList.remove('header_container_open')
            setTimeout(() => {
                container.style.display = 'none'
            }, 250)
        }
    }, [isOpen]);

    return (
        <header className={'header'}>
            <div className={'header_container'} id={'header_container'}>
                <Link to={'/'}>
                    <Logo/>
                </Link>

                <button type={'button'} className={'hamburger'} id={'hamburger'}
                        onClick={() => setIsOpen(prevState => !prevState)}
                >
                    <span></span>
                </button>
            </div>

            <div className={'extra_menu_container extra_menu_container_closed'}
                 id={'extra_menu_container'}
                 // onClick={() => setIsOpen(prevState => !prevState)}
                 // onClick={() => menuContainerToggle()}
            >
                <div className="extra_background"
                     onClick={() => setIsOpen(prevState => !prevState)}
                >
                </div>
                <div className={'extra_menu'}>
                    <button type={'button'} className={'hamburger'} id={'hamburger'}
                            onClick={() => setIsOpen(prevState => !prevState)}
                    >
                        <span></span>
                    </button>
                    <div className={'menu_item'} id={'menu_item-1'}>
                        <div className={'item_head'}
                             onClick={() => menuItemToggle(1)}
                        >Спецификация<MenuArrow/></div>
                        <div className={'item_body'}>
                            <Link to={'/money-policy'} className={'item'}>Политика по борьбе с отмыванием
                                денежных средств
                            </Link>
                            <Link to={'/poryadok-rassmotreniya-zhalob'} className={'item'}>Порядок рассмотрения
                                жалоб</Link>
                            <Link to={'/terms-and-conditions'} className={'item'}>Условия и положения</Link>
                            <Link to={'/risks'} className={'item'}>Раскрытие рисков</Link>
                            <Link to={'/security'} className={'item'}>Общее положение о защите данных</Link>
                            <Link to={'/dep-money'} className={'item'}>Пополнение и снятие средств</Link>
                            <Link to={'/confidential-policy'} className={'item'}>Политика конфиденциальности</Link>
                            <Link to={'/financial-support'} className={'item'}>Договор оказания услуг финансового
                                сопровождения</Link>
                        </div>
                    </div>

                    <div className={'menu_item'} id={'menu_item-2'}>
                        <div className={'item_head'}
                             onClick={() => menuItemToggle(2)}
                        >Партнеры<MenuArrow/></div>
                        <div className={'item_body'}>
                            <Link to={'https://ru.tradingview.com/'} className={'item'}
                                  target={'_blank'}
                            >Tradingview</Link>
                            <Link to={'https://ru.investing.com/'} className={'item'}
                                  target={'_blank'}
                            >Investing</Link>
                            <Link to={'https://personal.webonlinecentr.com/'} className={'item'}
                                  target={'_blank'}
                            >NCCweb</Link>
                            <Link to={'https://www.bybit.com/ru'} className={'item'}
                                  target={'_blank'}
                            >Bybit</Link>
                        </div>
                    </div>

                    <div className={'menu_item'} id={'menu_item-3'}>
                        <div className={'item_head'}
                             onClick={() => menuItemToggle(3)}
                        >Контакты<MenuArrow/></div>
                        <div className={'item_body'} id={'contact_us'}>
                            <Link to={'tel:+74999615275'}><PhoneIcon/>74999615275</Link>
                            <Link to={'mailto:clearncentr@nkc.expert'}><EmailIcon/>clearncentr@nkc.expert</Link>
                            <Link to={'tg://resolve?domain=onlinenccinfo'}><TelegramIcon/>@onlinenccinfo</Link>

                            У нас есть представительства в разных регионах РФ
                        </div>
                    </div>

                    <div className={'menu_item'} id={'menu_item-4'}>
                        <div className={'item_head'}
                             // onClick={() => menuItemToggle(4)}
                        ><Link to={'/important'}>ВАЖНО</Link></div>
                        <div className={'item_body'}>
                            <p><strong>ВНИМАНИЕ!</strong> nationalclearingcentre.ru работает только с юридическими
                                лицами и не предоставляет информацию по сотрудничеству с физическими лицами.
                                Если вы являетесь физическим лицом, информацию можно получить в разделе контакты или у
                                специалиста закрепленного за Вами.</p>
                        </div>
                    </div>

                    {/*<div className={'menu_item'} id={'menu_item-5'}>*/}
                    {/*    <div className={'item_head'}*/}
                    {/*         onClick={() => menuItemToggle(5)}*/}
                    {/*    >видеоматериалы<MenuArrow/></div>*/}
                    {/*    <div className={'item_body'}>*/}
                    {/*        <Link to={'/video-materials'}>webplatform</Link>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </header>
    );
}

export default Header;
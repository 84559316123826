import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {useState} from "react";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import MoneyPolicyScreen from "./Pages/MoneyPolicyScreen/MoneyPolicyScreen";
import HomePage from "./Pages/HomePage/HomePage";
import ReportsOrderScreen from "./Pages/ReportsOrderScreen/ReportsOrderScreen";
import TermsAndConditionsScreen from "./Pages/TermsAndConditions/TermsAndConditionsScreen";
import GoUpButton from "./Components/goUpButton/goUpButton";
import RisksScreen from "./Pages/RisksScreen/RisksScreen";
import DataSecurityScreen from "./Pages/DataSecurityScreen/DataSecurityScreen";
import DepMoney from "./Pages/DepMoney/DepMoney";
import ConfidentialPolicyScreen from "./Pages/ConfidentialPolicyScreen/ConfidentialPolicyScreen";
import VideoMaterialsPage from "./Pages/VideoMaterialsPage/VideoMaterialsPage";
import ImportantScreen from "./Pages/ImportantScreen/ImportantScreen";
import FinancialSupport from "./Pages/FinancialSupport/FinancialSupport";

function App() {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Router>
            <Header isOpen={isOpen} setIsOpen={setIsOpen}/>

            <Routes>
                <Route path='/' element={<HomePage setIsOpen={setIsOpen}/>} exact/>
                <Route path='/money-policy' element={<MoneyPolicyScreen setIsOpen={setIsOpen}/>}/>
                <Route path='/poryadok-rassmotreniya-zhalob' element={<ReportsOrderScreen setIsOpen={setIsOpen}/>}/>
                <Route path='/terms-and-conditions' element={<TermsAndConditionsScreen setIsOpen={setIsOpen}/>}/>

                <Route path='/risks' element={<RisksScreen setIsOpen={setIsOpen}/>}/>
                <Route path='/security' element={<DataSecurityScreen setIsOpen={setIsOpen}/>}/>
                <Route path='/dep-money' element={<DepMoney setIsOpen={setIsOpen}/>}/>
                <Route path='/confidential-policy' element={<ConfidentialPolicyScreen setIsOpen={setIsOpen}/>}/>
                <Route path='/financial-support' element={<FinancialSupport setIsOpen={setIsOpen}/>}/>

                <Route path='/video-materials' element={<VideoMaterialsPage setIsOpen={setIsOpen}/>}/>

                <Route path='/important' element={<ImportantScreen setIsOpen={setIsOpen}/>}/>
            </Routes>

            <Footer/>

            <GoUpButton/>
        </Router>
  );
}

export default App;

import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function DepMoney({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'depMoneyScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>ПОЛИТИКА ДЕПОЗИТА И ВЫВОДА</h1>

                <p>Торговля любыми инвестиционными инструментами, которые могут принести прибыль, требует, чтобы клиенты NCC вносили деньги на свой торговый-счет. Прибыль может быть снята с торгового-счета.</p>

                <p>Депозиты и снятие средств/возвраты регулируются настоящей политикой WD/возврата средств, а также общеприменимыми положениями и условиями.</p>

                <h2>ДЕПОЗИТЫ</h2>

                <p>Вы, Клиент, должны выполнять все депозиты из собственного источника (например, с банковского счета, электронного кошелька, наличными средствами). Если вы хотите начать торговать, вы должны убедиться, что учетная запись на ваше имя. Чтобы удостовериться, что подтверждение SWIFT является подлинным, оно должно быть отправлено в NCC для подтверждения происхождения денег, которые будут использоваться для торговли. Если вы не соблюдаете эту политику WD, вы не сможете внести деньги с помощью банковского перевода. Если вы не вошли в систему и не торговали со своей учетной записи в течение шести (6) месяцев (неактивная учетная запись), с вашей неактивной учетной записи будет ежемесячно удерживаться 10 % (неактивная комиссия). Доступна частичная активация счета, при частичной активации торговля не осуществляется до полной активации , а закрепляется место инвестора.</p>

                <h2>ВЫВОД ПРИБЫЛИ</h2>

                <p>Согласно общепринятым правилам, первое снятие средств должно осуществляться только через тот же
                    банковский счет или кредитную/дебетовую карту, которые вы использовали для внесения средств. Если мы
                    не договорились об ином снятии средств.</p><p>Не отступая от вышеизложенного, NCC может осуществлять
                снятие средств на другое средство, отличное от того, которое использовалось для депозита.</p><p>Кроме
                того, когда дело доходит до снятия или крупного ввода средств , от Клиента может потребоваться
                предоставление дополнительной информации и документов.</p>
                <p>В соответствии с требованиями по борьбе с отмыванием денег (AML) Компания проверяет, чтобы доходы от
                    торговли переводились на счет, где бенефициарный владелец тот же, что и на торговом счете. Поэтому
                    рекомендуется предоставить Компании копию выписки по банковскому счету или кредитной карте, на
                    которую будут отправлены средства, до подачи запроса на снятие средств.</p><p>Снятие будет касаться только клиента. Компания не будет осуществлять снятие средств на какой- либо другой сторонний или анонимный счет.</p>

                <p>Вывод частичной суммы активации доступен после полной активации счета, в ином случае удерживается комиссия на затраты в виде 85% от суммы частичной активации. После полной активации комиссия на вывод не взимается.</p>

                <h2>ПОЛУЧЕНИЕ СРЕДСТВ</h2>

                <p>В банках и кредитных организациях могут быть разные внутренние процедуры зачисления полученных средств. Важно отметить, что Компания не контролирует, когда средства будут доступны. Если вам требуется подтверждение транзакции, обратитесь в службу поддержки.</p>

                <h2>ПЕРВОНАЧАЛЬНЫЕ ДЕПОЗИТЫ С ДЕБЕТОВЫХ/КРЕДИТНЫХ КАРТ</h2>

                <p>В случае депозита с кредитной карты, Компания сначала возместит всё до суммы, депонированной через ту
                    же карту. В некоторых случаях компания-эмитент кредитной карты устанавливает ограничение по времени,
                    которое может пройти для выдачи возмещения. По истечении этого времени средства будут возвращены
                    клиенту банковским переводом. Если это так ,или есть прибыль, обратитесь к разделу выше, касающемуся
                    обработки.</p><p>Процесс вывода средств в NCC, как подробно описано выше, построен на основе строгих
                правил, чтобы убедиться, что средства надежно отправлены обратно к их первоначальному источнику и
                получателю.</p><p>Если мы не договорились об ином, Снятие/Возврат средств со Счета может производиться
                только в той же валюте, в которой был сделан соответствующий депозит.</p><p>Не отступая от вышеизложенного, NCC может осуществлять снятие средств/возврат средств на другой объект, отличный от того, который использовался для депозита, в соответствии с правилами по борьбе с отмыванием денег.</p>

                <h2>БЕЗДЕПОЗИТНЫЕ СРЕДСТВА</h2>

                <p>Средства, появляющиеся на счете Клиента, включают в себя согласованные или добровольные бонусы и
                    поощрения, а также любые другие суммы, не внесенные непосредственно Клиентом или полученные в
                    результате торговли за счет фактически внесенных средств (недепонированные средства). Обратите
                    внимание, если не оговорено иное, недепонированные средства недоступны для вывода/возврата. Кроме
                    того, из-за технических ограничений недепонированные средства.
                    могут быть начислены на счет Клиента в определенных случаях (например, для технических целей,
                    позволяющих закрыть позиции или счет с задолженностью).
                </p><p>Не отступая от вышеизложенного, резервные средства, выдаваемые Клиенту через NCC, могут быть сняты только при выполнении минимального объема торгов, который оговаривается заранее с экспертом.</p>

                <h2>ПОДАЧА ЗАПРОСА НА СНЯТИЕ СРЕДСТВ/ВОЗВРАТ</h2>

                <p>Чтобы обработать ваш запрос на снятие/возврат средств, вы должны:</p>
                <ul>
                    <li>Открыть запрос на вывод/возврат из личного кабинета.</li>
                    <li>Войдите в свою учетную запись через веб-сайт, нажмите «Запрос на вывод средств », заполните
                        информацию в форме «Запрос на вывод средств».
                    </li>
                    <li>Вся документация по соблюдению требований должна быть получена и утверждена сотрудником отдела
                        по соблюдению требований NCC, чтобы можно было приступить к снятию
                        средств/возмещению средств.
                    </li>
                    <li>Имя бенефициара должно совпадать с именем на торговом счете. Запросы на перевод средств третьему лицу не обрабатываются.</li>
                </ul>

                <p>Важно: владелец счета должен регулярно контролировать счет и убедиться, что на счете имеется доступная маржа, прежде чем подавать этот запрос, поскольку такое снятие/возврат может повлиять на существующие открытые позиции или используемую торговую стратегию.</p>

                <h2>ВРЕМЯ ОБРАБОТКИ ВОЗВРАТА</h2>

                <p>Время, необходимое для поступления денег на вашу кредитную карту или банковский счет, который
                    использовался для внесения средств, может варьироваться (обычно до пяти рабочих дней). Обратите
                    внимание, что вывод/возврат средств на банковские счета может занять больше времени из-за
                    действующих дополнительных процедур безопасности.</p><p>Как правило, запрос обрабатывается NCC в
                течение 1–3 рабочих дней с момента получения. Во избежание каких-либо задержек, пожалуйста, внимательно
                проверьте свою информацию перед отправкой запроса.</p><p>NCC не несет ответственности за ошибки или
                неточности, допущенные владельцем счета. NCC не может отслеживать и никоим образом не несет
                ответственности за внутренние процедуры компании-клиента, выпускающей кредитные карты, или банка. Клиент
                должен самостоятельно связаться со своей кредитной компанией или соответствующим банком.</p><p>Средства
                переводятся на ваш кредитный счет после того, как продавец вашей кредитной карты списал средства с
                нашего счета. Этот процесс может занять до 5 рабочих дней или более, чтобы отобразиться на вашей
                кредитной карте.</p>
                <p>Пожалуйста, обратите внимание, что мы не привязаны к каким-либо временным рамкам, и что любые дополнительные сборы, налагаемые третьими лицами, будут вычтены из депозита или Снятия/Возмещения, в зависимости от обстоятельств.</p>

                <h2>КРЕДИТНЫЕ/ДЕБЕТОВЫЕ КАРТЫ</h2>

                <p>Для депозитов с кредитной/дебетовой карты, когда вы выбираете счет в валюте, доллара США, с вашей
                    кредитной/дебетовой карты будет списана сумма в соответствии с обменным курсом.</p><p>Если вы использовали кредитную карту для внесения денег, совершения онлайн-торговли и решили обналичить свою прибыль, при первом снятии необходимо использовать ту же кредитную/ дебетовую карту.</p>
            </div>
        </section>
    );
}

export default DepMoney;
import React, {useEffect, useState} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function MoneyPolicyScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'moneyPolicyScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>Политика по борьбе с отмыванием денежных средств</h1>

                <h2>БОРЬБА С ОТМЫВАНИЕМ ДЕНЕГ</h2>

                <p>
                    NCC поддерживает борьбу с отмыванием денег. NCC следует рекомендациям, установленным Объединенной
                    руководящей группой по борьбе с отмыванием денег. Компания является полноправным членом Группы
                    разработки финансовых мер борьбы с отмыванием денег (FATF), межправительственной организации,
                    целью которой является борьба с отмыванием денег и финансированием терроризма.
                </p>

                <p>Теперь у NCC есть политика, которая борется с отмыванием денег. Это включает в себя:</p>
                <ul>
                    <li>
                        Требовать у клиентов удостоверение личности, для подтверждения того, что клиенты не являются
                        известными или подозреваемыми в терроризме, путем сверки их имен со списками известных или
                        подозреваемых в терроризме.
                    </li>

                    <li>
                        Информировать клиентов о том, что информация, которую они предоставляют, служит для
                        подтверждения
                        их личности.
                    </li>

                    <li>Не принимать переводы Western Union.</li>
                </ul>

                <p>
                    Отмывание денег происходит, когда средства от незаконной/преступной деятельности перемещаются через
                    финансовую систему таким образом, что создается впечатление, что средства получены из законных
                    источников.
                </p>

                <h2>ОТМЫВАНИЕ ДЕНЕГ ОБЫЧНО ПРОИСХОДИТ В ТРИ ЭТАПА:</h2>
                <ul>
                    <li>Во-первых, денежные средства или их эквиваленты помещаются в финансовую систему.</li>

                    <li>
                        Во-вторых, деньги переводятся или перемещаются на другие счета (например, фьючерсные счета)
                        посредством серии финансовых транзакций, призванных скрыть происхождение денег (например,
                        выполнение сделок с небольшим финансовым риском или без него или перевод остатков на счетах
                        на другие счета).
                    </li>

                    <li>
                        И, наконец, средства вновь вводятся в экономику таким образом, что создается впечатление,
                        что средства получены из законных источников (например, закрытие фьючерсного счета и перевод
                        средств на банковский счет).
                    </li>
                </ul>

                <p>
                    Международная борьба с отмыванием денег требует, чтобы учреждения, предоставляющие финансовые услуги,
                    были осведомлены о потенциальных злоупотреблениях по отмыванию денег, которые могут быть у клиента,
                    и внедрить программу соблюдения для предотвращения, обнаружения и сообщения о потенциальной
                    подозрительной деятельности.
                </p>

                <p>
                    Эти рекомендации были реализованы для защиты NCC и ее клиентов.
                </p>

                <p>
                    Если у вас есть вопросы/комментарии относительно этих рекомендаций, свяжитесь с нами по адресу
                    clearncentr@nkc.expert
                </p>

                <h2>ЧТО ТАКОЕ ОТМЫВАНИЕ ДЕНЕГ?</h2>

                <p>
                    Отмывание денег — это придание законного вида доходам от преступной деятельности. Чтобы
                    замаскировать настоящее происхождение «грязных» денег, используют разные схемы — неизменно одно:
                    «отмывают» всегда доходы, полученные в результате преступления, например наркоторговли, уклонения
                    от уплаты налогов или коррупции.
                </p>

                <p>
                    Первая защита от отмывания денег — сложная проверка «Знай своего клиента» (КУС). Чтобы обеспечить
                    соблюдение стандартных правил AML, мы требуем, чтобы вы предоставили следующую документацию:
                </p>
                <ul>
                    <li>
                        Удостоверение личности: четкая копия лицевой и оборотной сторон вашего удостоверения личности
                        с фотографией, т. е. действующий паспорт, водительские права .
                    </li>

                    <li>
                        Подтверждение места жительства: официальный документ, выданный в течение последних 3 месяцев,
                        в котором четко указаны ваше имя и адрес регистрации. Это может быть счет за
                        коммунальные услуги (например, за воду, электричество или стационарный телефон) или выписка из
                        банка. Пожалуйста, убедитесь, что документ включает:
                    </li>

                    <li>
                        Полное ФИО
                    </li>

                    <li>
                        Адрес проживания
                    </li>

                    <li>
                        Дата выдачи (в течение последних 3 месяцев)
                    </li>

                    <li>
                        Название органа, выдавшего документ, с официальным логотипом или печатью.
                    </li>
                </ul>

                <p>
                    Обратите внимание, что нормативные требования различаются в некоторых странах, и нам может
                    потребоваться дополнительная документация от вас.
                </p>

                <h2>МОНИТОРИНГ</h2>

                <p>
                    Мы не принимаем сторонние платежи. Все депозиты должны быть сделаны на ваше имя и соответствовать
                    представленным документам КУС. В соответствии с регулятивной политикой AML, все снятые средства
                    должны быть возвращены точно в тот источник, из которого они были получены. Это означает, что
                    средства, изначально внесенные посредством банковского перевода, будут возвращены на тот же счет
                    при подаче запроса на снятие средств. Точно так же, если вы внесли депозит с помощью кредитной
                    карты, ваши средства будут возвращены на ту же кредитную карту, когда будет сделан запрос на снятие
                    средств.
                </p>

                <h2>СОСТАВЛЕНИЕ ОТЧЕТОВ</h2>

                <p>
                    Регулирование ПОД требует от нас мониторинга и отслеживания подозрительных транзакций, в том числе
                    сообщения о таких действиях в соответствующие правоохранительные органы. Мы также оставляем за
                    собой право отказать в переводе на любом этапе, если мы считаем, что перевод каким- либо образом
                    связан с преступной деятельностью или отмыванием денег. Закон запрещает нам информировать клиентов
                    о поданных в правоохранительные органы сообщениях о подозрительной деятельности.
                </p>
            </div>
        </section>
    );
}

export default MoneyPolicyScreen;
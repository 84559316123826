import React from 'react';
import './ThirdSection.sass'
import LeadForm from "../LeadForm/LeadForm";

function ThirdSection() {
    return (
        <section id={'thirdSection'}>
            <h3>трехдневный курс на одной из лучших торговых платформ</h3>

            <LeadForm/>

            {/*<button onClick={() => {*/}
            {/*    document.getElementById('leadForm-background').classList.add('leadForm-background-active')*/}
            {/*    document.getElementById('leadForm-popup').classList.add('leadForm-popup-active')*/}
            {/*}}>Записаться*/}
            {/*</button>*/}
        </section>
    );
}

export default ThirdSection;
import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function RisksScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'risksScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>Раскрытие рисков</h1>

                <h2>ОБЛАСТЬ УВЕДОМЛЕНИЯ</h2>

                <p>NCC (далее NCC Фирма, мы, мы и наш) предоставляет вам это Уведомление об отказе от ответственности
                    (Уведомление), чтобы помочь вам понять риски, которые могут возникнуть при торговле контрактами на
                    разницу цен (CFD). Однако вам необходимо помнить, что Уведомление не содержит всех рисков и
                    аспектов, связанных с торговлей CFD. Клиент (именуемый Клиентом, вы, вы и вы сами) должен
                    внимательно прочитать Уведомление в сочетании с Клиентским
                    соглашением, Политикой исполнения ордеров и документацией/информацией, доступной вам через наш
                    Веб-сайт.
                </p>

                <p>Вам необходимо убедиться, что любое решение об участии в торговле CFD принимается на информированной
                    основе и с учетом ваших знаний и опыта, а также ваших личных обстоятельств.</p>

                <p>(включая, помимо прочего, ваше финансовое положение). Кроме того, вам необходимо убедиться, что вы
                    понимаете природу CFD и степень всех рисков и аспектов, связанных с торговлей CFD.</p>

                <p>Обратите внимание, что CFD являются финансовыми продуктами с кредитным плечом, и поэтому торговля CFD
                    сопряжена с высоким риском убытков, поскольку на движение цены влияет размер кредитного плеча,
                    которое использует клиент. Например, если клиент использует 50-кратное
                    кредитное плечо, движение на 0,5% приведет к прибыли или убытку в размере 25%. Тем не менее, в
                    результате защиты от отрицательного баланса (NВР) вы не можете потерять больше, чем ваши
                    первоначальные инвестиции.
                </p>

                <p>Торговля CFD подходит не всем. Ни при каких обстоятельствах вы не должны рисковать больше, чем готовы
                    потерять.</p>

                <p>Для любого термина с заглавной буквы, который не был определен в Уведомлении, см. Приложение
                    (Глоссарий) Клиентского соглашения.</p>

                <h2>СООТВЕТСТВУЮЩАЯ ОЦЕНКА НАПРЯЖЕННОСТИ</h2>

                <p>При обработке вашей Формы открытия счета, NCC оценивает вашу пригодность для торговли CFD и
                    определяет на основе информации, которую вы нам предоставляете, достаточно ли у вас знаний и опыта,
                    чтобы понимать риски, связанные с торговлей CFD. Мы проинформирует вас о результатах нашей оценки,
                    но это не освобождает вас от необходимости тщательно взвешивать, стоит ли торговать CFD. Если мы
                    предупреждаем вас, что торговля CFD может вам не подойти, вам следует воздержаться от торговли CFD
                    до тех пор, пока вы не приобретете достаточные знания и опыт.</p>

                <h2>ПРИРОДА CFD</h2>

                <p>CFD — это соглашения об обмене разницы в стоимости определенного инструмента или валюты
                    между моментом заключения соглашения и временем его закрытия. CFD позволяют клиентам Фирм
                    воспроизводить экономический эффект от торговли определенными валютами или другими инструментами, не
                    требуя фактического владения этими активами; полный список CFD, предлагаемых NCC доступен на нашем
                    веб-сайте.
                </p><p>CFD — это производные продукты, торгуемые вне биржи (или внебиржевой (ОТС)); это означает, что
                NCC всегда является контрагентом по сделкам Клиента, и любые сделки с CFD, заключенные с Фирмой, могут
                быть закрыты только у нас. Наша способность открывать и/или закрывать сделки зависит от доступности
                нашей торговой платформы (платформ).</p><p>Вы понимаете, что не имеете права на физическую поставку
                базового инструмента (или эталонного инструмента) CFD, которыми вы торгуете, и у вас нет прав на базовый
                инструмент (например, права голоса, если вы торгуете CFD на акции).</p><p>Стоимость CFD колеблется в
                течение дня; движение цен CFD определяется рядом факторов, включая, помимо прочего, доступность рыночной
                информации.</p>

                <h2>ЦЕНЫ И СТОИМОСТЬ</h2>

                <p>Цены, генерируемые нашей торговой платформой (платформами), являются производными от цен
                    соответствующих базовых инструментов, которые Фирма получает от сторонних поставщиков ликвидности
                    цен. Цены на CFD, которыми вы торгуете у нас, включают наценку; это означает, что спреды,
                    предлагаемые нами, состоят из необработанных спредов, полученных от поставщика (поставщиков)
                    ликвидности/цены и наценки (где применимо).</p><p>Для торговли определенными контрактами на разницу
                цен от Клиента может потребоваться уплата комиссионных и/или других сборов; эти случаи подробно описаны
                на нашем веб-сайте. Для всех типов CFD, предлагаемых Fir, комиссия (если применимо) и
                финансирование/комиссионные сборы за овернайт не включены в котировки Фирмы, а вместо этого взимаются
                непосредственно со Счета(ов) Клиента. В случае комиссий за финансирование/овернайт стоимость открытых
                позиций по некоторым видам финансовых инструментов увеличивается или уменьшается за счет ежедневного
                свопа на комиссию и финансирования в течение всего срока сделки. Плата за финансирование основана на
                преобладающих рыночных процентных ставках. С понедельника по четверг своп начисляется один раз за каждый
                рабочий день, а по пятницам своп начисляется в тройном размере с учетом выходных; подробная информация о
                применяемых ежедневных сборах за финансирование / перерасход средств доступна на нашем веб-сайте.</p>
                <p>NCC выступает в качестве маркет-мейкера при исполнении сделок Клиента, и Фирма может получать прибыль
                    от любых убытков Клиента.</p><p>Вы не должны: пополнять свой счет, используя не собственные
                средства. Например, если вы понесете убыток по своим сделкам, вам все равно придется выплатить любую
                сумму долга, а также любые проценты или другие расходы. Следовательно, вы никогда не должны заключать
                какие-либо сделки на чужие деньги, и вы никогда не должны полагаться на то, что сможете получить прибыль
                от любой сделки, чтобы погасить такие суммы.</p>

                <h2>РЫНОЧНЫЕ УСЛОВИЯ, ТРЕБУЕМАЯ МАРЖА, РЫЧАЖНОЕ И СТОП-АУТ УРОВНИ</h2>

                <p>Торговля CFD позволяет вам использовать кредитное плечо для открытия сделки, путем внесения доли от
                    общей стоимости сделки; это означает, что относительно небольшое движение рынка может привести к
                    пропорционально большему изменению стоимости вашей сделки. Для целей расчета маржи используемый
                    уровень кредитного плеча будет наименьшим из: Счета или торгуемого символа. Эта логика применима ко
                    всем нашим торговым платформам.</p><p>Максимальное кредитное плечо, предлагаемое NCC составляет
                1:200, хотя для некоторых CFD
                могут применяться ограничения кредитного плеча. Пожалуйста, посетите веб-сайт для получения
                дополнительной информации.
            </p><p>Финансовые рынки могут быстро колебаться, отражая события, которые находятся вне контроля Фирмы и/или
                вашего контроля; в результате цены станут волатильными. Одной из форм волатильности цен является
                гэппинг, возникающий при внезапном переходе цен с одного уровня на другой. Это может быть вызвано,
                например, неожиданными экономическими событиями или рыночными объявлениями в рабочее или нерабочее
                время. Следовательно, NCC может быть не в состоянии выполнить ваши инструкции по запрошенной цене. Кроме
                того, если цены будут

                двигаться против вас, это окажет прямое влияние на ваши сделки в режиме реального времени, которые могут
                быть автоматически остановлены. Возможно, что все ваши сделки будут остановлены; не только те, которые
                приносят убытки.
            </p><p>Вы должны иметь в виду, что любые изменения на уже торгуемом Счете, могут немедленно повлиять на ваши
                открытые позиции и привести к стоп-ауту.</p><p>Вы несете ответственность за мониторинг требуемой маржи
                ваших открытых позиций, и во избежание стоп-аута вы должны пополнить свой счет.</p><p>Для получения
                дополнительной информации см. раздел «Маржа и кредитное плечо» Политики исполнения ордеров.</p>

                <h2>ВАЛЮТНЫЕ И ДРУГИЕ СООТВЕТСТВУЮЩИЕ РИСКИ</h2>

                <p>На вас повлияют колебания валютных курсов, если вы торгуете продуктом, номинированным в валюте,
                    отличной от валюты вашего Счета. Любые расчеты по конвертации валюты предоставляются Фирмой Клиенту
                    в валюте, в которой деноминирован счет Клиента, и в валюте соответствующего CFD с использованием
                    кросс-спот-курса.</p><p>На нашу способность торговать CFD также могут повлиять изменения в правовой,
                нормативной, налоговой среде и/или другие факторы.</p>

                <h2>ТЕХНИЧЕСКИЕ РИСКИ</h2>

                <p>Мы стараемся постоянно генерировать цены и предоставлять вам доступ к нашим торговым платформам в
                    течение торговых сессий, как указано на нашем веб-сайте. Однако , бывают случаи, когда это
                    невозможно; например, случаи плохого подключения к телекоммуникациям/интернету, неполадки и перебои
                    в работе системы и/или другие факторы. Вышеизложенное может привести к изменению цен между моментом
                    размещения заказа и моментом получения заказа Фирмой. Кроме того, эти технические риски могут
                    существенно повлиять на исполнение ваших ордеров.</p><p>* Доступ к нашим торговым платформам
                включает доступ через мобильные приложения.</p>

                <h2>ПОСЛЕДНИЕ ПОКАЗАТЕЛИ</h2>

                <p>Прошлые показатели, моделирование или прогнозирование CFD не указывают на будущие результаты.
                    Обратите внимание, что стоимость ваших инвестиций может уменьшаться (а также увеличиваться) по мере
                    того, как рыночная цена базового актива колеблется вниз (или вверх).</p>

                <h2>ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</h2>

                <p>Для получения дополнительной информации см. Руководство по инвестированию, выпущенное Европейским
                    управлением по ценным бумагам и рынкам (ЕСМА), и Предупреждение для инвесторов о контрактах на
                    разницу цен (CFD), выпущенное совместно ESMA и Европейским банковским управлением (ЕВА); также
                    доступны на нашем веб-сайте.</p>
            </div>
        </section>
    );
}

export default RisksScreen;
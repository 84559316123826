import React from 'react';
import './SectionHeader.sass'

function SectionHeader() {
    return (
        <div className={'sectionHeader'}>

        </div>
    );
}

export default SectionHeader;
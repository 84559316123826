import React, {useState} from 'react';

import './LeadForm.sass'
import axios from "axios";
import emailjs from '@emailjs/browser'

import {ReactComponent as CheckBox} from "../../Media/Icons/checkBox.svg";
import {ReactComponent as CheckBoxChecked} from "../../Media/Icons/checkBoxChecked.svg";

function LeadForm(props) {
    const [fio, setFio] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [invalidForm, setInvalidForm] = useState(false)

    const [fioClicked, setFioClicked] = useState(false)
    const [phoneClicked, setPhoneClicked] = useState(false)

    const [isChecked, setIsChecked] = useState(false)

    const phoneInput = (e) => {
        const numberLength = 11

        const value = e.target.value.replace(/\D+/g, "");

        let result = ""

        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 0:
                    result += "+7 (";
                    continue;
                case 4:
                    result += ") ";
                    break;
                case 7:
                    result += "-";
                    break;
                case 9:
                    result += "-";
                    break;
                default:
                    break;
            }

            result += value[i];
        }

        return result
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        if (fio && phone && phone.length === 18) {
            const emailForm = {
                from_name: fio,
                from_email: email ? email : 'test@gmail.com',
                from_phone: phone,
            }
            await axios.post('https://api.trd-in.finance/v1/affiliate/users/register', {
                email: email ? email : `test${Math.random(1000000)}@gmail.com`,
                firstName: fio,
                lastName: "_",
                password: "lk23jg5645g6jh",
                retypePassword: "lk23jg5645g6jh",
                phone: phone,
                countryName: "Russia",
                affiliateSecretKey: "7dlr6Ik6_ID8XtINR8Ps0x7Z_NZpzG9BSfwdEPOSB2wDavbE2_qFbh1U-1zv9dEI",
                referralInfo: "trainig-nccentr.ru"
            })
                .then(() => {
                    // console.log('ok')
                    window.ym(97383224, 'reachGoal', 'form_sending')
                    setInvalidForm(false)
                    setFio('')
                    setPhone('')
                    setEmail('')
                    document.getElementById('leadForm-popup').classList.remove('leadForm-popup-active')
                    document.getElementById('congrats').classList.add('congrats-active')
                    document.getElementById('leadForm-background').classList.add('leadForm-background-active')
                })
                .then(() => {
                    emailjs.send('service_nz35cjg', 'template_evod5m9', emailForm, {
                        publicKey: 'nlGOgm7qMu4y1qMt5',
                    })

                    console.log('success')
                    setPhoneClicked(false)
                    setFioClicked(false)
                    setIsChecked(false)
                })
        }
    }

    return (
        <div id={'leadForm-container'}>
            <div id="leadForm-background" onClick={() => {
                document.getElementById('leadForm-background').classList.remove('leadForm-background-active')
                document.getElementById('leadForm-popup').classList.remove('leadForm-popup-active')
                document.getElementById('congrats').classList.remove('congrats-active')
            }}></div>

            <div id="congrats">
                <h1>Заявка принята</h1>
                <h4>Ожидайте, в ближайшее время с Вами свяжется специалист для получения первичной информации!</h4>

                <button onClick={() => {
                    document.getElementById('leadForm-background').classList.remove('leadForm-background-active')
                    document.getElementById('congrats').classList.remove('congrats-active')
                }}>Закрыть</button>
            </div>

            <form id="leadForm-popup" onSubmit={e => submitHandler(e)}>
                <h2>Оставьте заявку</h2>
                {/*<span>Имя</span>*/}
                <input type="text" onChange={e => setFio(e.target.value)}
                       value={fio} placeholder={'Имя'} className={!fio && fioClicked ? 'wrongInput' : ''}
                       onClick={() => setFioClicked(true)}
                />

                {/*<span>Телефон</span>*/}
                <input type="text" onChange={e => setPhone(phoneInput(e))}
                       value={phone} placeholder={'Телефон'} className={phone.length !== 18 && phoneClicked ? 'wrongInput' : ''}
                       onClick={() => setPhoneClicked(true)}
                />

                {/*<p className={'policy-check'} onClick={() => setIsChecked(prevState => !prevState)}*/}
                {/*><span>Я согласен на обработку моих персональных данных</span> {isChecked ? <CheckBoxChecked/> : <CheckBox/>}</p>*/}

                {/*<span>Почта</span>*/}
                {/*<input type="text" onChange={e => setEmail(e.target.value)}*/}
                {/*       value={email}*/}
                {/*/>*/}

                {/*<span id="invalidForm" className={invalidForm ? 'invalidForm-active' : ''}>Введены некорректные данные!</span>*/}

                <button type={'submit'}>Записаться</button>
            </form>
        </div>
    );
}

export default LeadForm;
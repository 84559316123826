import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function ImportantScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'importantScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>ВНИМАНИЕ!</h1>

                <p>
                    nationalclearingcentre.ru работает только с юридическими
                    лицами и не предоставляет информацию по сотрудничеству с физическими лицами.
                    Если вы являетесь физическим лицом, информацию можно получить в разделе контакты или у
                    специалиста закрепленного за Вами.
                </p>

                <p>
                    <strong>Предупреждение: </strong>совершение операций с беспоставочными внебиржевыми финансовыми
                    инструментами
                    имеет рискованный характер. Возможность получения прибыли неразрывно связана с риском получения
                    убытков. При совершении операций к клиентам не переходят право собственности и иные права на
                    базовые активы. Полученные прибыли в прошлом не гарантируют прибылей в будущем. Воспользуйтесь
                    обучающими сервисами от компании для понимания рисков, прежде, чем начинать операции.
                </p>

                <p>
                    <strong>
                        Перед началом сотрудничества, внимательно ознакомьтесь с разделом "Спецификация", начиная работу с
                        организацией Вы автоматически даете согласие на все пункты указанные в этом разделе
                    </strong>
                </p>
            </div>
        </section>
    );
}

export default ImportantScreen;
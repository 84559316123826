import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function ReportsOrderScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'reportsOrderScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>ПОРЯДОК РАССМОТРЕНИЯ ЖАЛОБ</h1>

                <p>
                    Ниже вы можете найти подробное описание нашей процедуры рассмотрения жалоб, которая
                    следует в случае, если вы хотите подать жалобу в компанию, в любое время.
                </p>

                <p>
                    Если мы не можем ответить на ваш вопрос, или вы считаете, что наш ответ неудовлетворителен, вы
                    можете
                    подать официальную жалобу, бесплатно, на почту clearncentr@nkc.expert.
                </p>

                <p>
                    Мы рассмотрим вашу жалобу и предоставим Вам ответ в течение 5 рабочих дней, следующих со дня её
                    получения. Мы проведем беспристрастное рассмотрение жалобы с целью понять, что произошло и оценить,
                    действовали ли мы справедливо, в рамках наших прав, и выполнили ли наши договорные и регуляторные
                    обязательства.
                </p>

                <p>
                    Вам будет отправлен письменный ответ.
                </p>

                <p>
                    В случае, если мы не сможем разрешить жалобу в течение 5 рабочих дней, в связи со спорной ситуацией,
                    полный и окончательный письменный ответ будет предоставлен не позднее, чем через восемь недель после
                    даты получения нами жалобы.
                </p>

                <h2>ТОЛКОВАНИЕ ТЕРМИНОВ</h2>

                <p>
                    Если не указано иное, термины, включенные в настоящий Отчет, имеют особое значение.
                </p>

                <h2>ОБЪЕМ РАССМОТРЕНИЯ ЖАЛОБ ПРОЦЕДУРА</h2>

                <p>
                    Процедура рассмотрения жалоб (Процедура) устанавливает процессы, используемые, когда ведется работа
                    с
                    жалобами, полученными от клиентов.
                </p>

                <h2>ОПРЕДЕЛЕНИЕ ЖАЛОБЫ</h2>

                <p>
                    Жалоба — это выражение неудовлетворенности со стороны клиента в отношении предоставления
                    инвестиционных
                    и/или вспомогательных услуг, предоставляемых NCC.
                </p>

                <p>Жалоба должна содержать:</p>
                <ul>
                    <li>имя и фамилия клиента;</li>

                    <li>номер торгового счета клиента;</li>

                    <li>затронутые номера транзакций, если применимо;</li>

                    <li>дата и время возникновения проблемы;</li>

                    <li>описание вопроса.</li>
                </ul>

                <p>Жалоба не должна содержать:</p>
                <ul>
                    <li>оскорбительные выражения в адрес NCC или сотрудника NCC.</li>
                </ul>

                <h2>ПРОЦЕДУРА</h2>

                <p>
                    Все жалобы должны быть в письменной форме и адресованы, в первую очередь, в отдел поддержки
                    клиентов.
                    Если клиент получает ответ от отдела поддержки клиентов, но считает, что жалобу необходимо подать
                    дальше, клиент может либо попросить отдел поддержки клиентов передать ее в отдел
                    нормативно-правового
                    соответствия, либо напрямую связаться с отделом нормативно-правового соответствия
                    (clearncentr@nkc.expert), который проведет независимое и беспристрастное расследование.
                </p>

                <p>
                    Как Отдел поддержки клиентов, так и Отдел соответствия должны тщательно рассматривать любые жалобы
                    по
                    мере необходимости (принимая во внимание любую информацию, содержащуюся в бухгалтерских книгах и
                    отчетах Фирмы, включая, помимо прочего, журнал торговых счетов клиентов) для достижения
                    справедливого
                    результата.
                </p>

                <p>
                    Как отдел поддержки клиентов, так и отдел нормативно-правового соответствия должны:
                </p>
                <ul>
                    <li>1. направить
                        первоначальный ответ клиенту в течение 48 часов.</li>
                    <li>2. разрешить жалобу в кратчайшие сроки.</li>
                    <li>3.
                        соответствующим образом проинформировать клиента.</li>
                </ul>

                <p>Все жалобы должны рассматриваться конфиденциально.</p>

                <h2>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>

                <p>Вопросы по данному Порядку следует адресовать, в первую очередь, в Отдел обслуживания клиентов.</p>

                <h2>КОНТАКТЫ</h2>

                <p>Электронная почта отдела поддержки клиентов: clearncentr@nkc.expert</p>
            </div>
        </section>
    );
}

export default ReportsOrderScreen;
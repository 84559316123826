import React from 'react';

import './SecondSection.sass'
import Image from '../../Media/Images/simplification_image.png'

import {ReactComponent as FirstIcon} from '../../Media/Icons/simplification_icon_1.svg'
import {ReactComponent as SecondIcon} from '../../Media/Icons/simplification_icon_2.svg'
import {ReactComponent as ThirdIcon} from '../../Media/Icons/simplification_icon_3.svg'
import {ReactComponent as FourthIcon} from '../../Media/Icons/simplification_icon_4.svg'

import {ReactComponent as FirstBenefit} from '../../Media/Icons/benefit_1.svg'
import {ReactComponent as SecondBenefit} from '../../Media/Icons/benefit_2.svg'
import {ReactComponent as ThirdBenefit} from '../../Media/Icons/benefit_3.svg'

function SecondSection() {
    return (
        <section id={'secondSection'}>
            <div className="section_container">
                {/*<h2>КАК ИИ УПРОЩАЕТ ТРЕЙДИНГ?</h2>*/}

                <div className="simplification">
                    <div className="feature">
                        <h4>
                            Возможности Искусственного интеллекта на биржах
                        </h4>

                        <img src={Image} alt="Возможности Искусственного интеллекта на биржах"/>

                        <p>Система учитывает существующие тренды на рынке, чтобы затем обновляться с учетом полученных
                            данных. Иными словами, ИИ имитирует мышление аналитиков </p>
                    </div>

                    <div className="feature">
                        <div className="item">
                            <FirstIcon/>
                            <p>Подборка данных</p>
                        </div>

                        <div className="item">
                            <SecondIcon/>
                            <p>Анализ данных</p>
                        </div>

                        <div className="item">
                            <ThirdIcon/>
                            <p>Управление портфелем</p>
                        </div>

                        <div className="item">
                            <FourthIcon/>
                            <p>Создание стратегии</p>
                        </div>
                    </div>
                </div>

                {/*<h2>польза ии в трейдинге</h2>*/}

                <div className="benefit">
                    <div className="item">
                        <FirstBenefit/>

                        <p>
                            Исследуя заголовки статей, новостных лент и постов в соц. сетях и блогах ИИ прогнозирует
                            изменение стоимости акций и возможные действия иных участников
                        </p>
                    </div>

                    <div className="item">
                        <SecondBenefit/>

                        <p>
                            Системы на базе искусственного интеллекта очень легки в использовании, они работают
                            прозрачно, и трейдинг проходит осторожно
                        </p>
                    </div>

                    <div className="item">
                        <ThirdBenefit/>

                        <p>
                            Икусственный интеллект дает возможность рационально проводить процедуру инвестирования и
                            грамотно вкладывать активы
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecondSection;
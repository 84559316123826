import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

import './VideoMaterialsPage.sass'

import video1 from '../../Media/VideoIMG_0609.MP4'
import video2 from '../../Media/VideoIMG_0609.webm'
import video3 from '../../Media/VideoIMG_0609.ogv'
import video4 from '../../Media/videoimg_0609.avi'

function VideoMaterialsPage({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'videoMaterialsScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>видеоматериал</h1>

                <h2>Webplatform</h2>

                <video playsInline controls muted width="100%" height="auto" controlsList="nodownload"
                       onContextMenu="return false;">
                    <source src={video1} type="video/mp4"/>
                    <source src={video2} type="video/webm"/>
                    <source src={video3} type="video/ogg"/>
                    <source src={video4} type="video/x-msvideo"/>
                </video>
            </div>
        </section>
    );
}

export default VideoMaterialsPage;
import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function TermsAndConditionsScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'termsAndConditionsScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>Условия и положения</h1>

                <h2>1. ВВЕДЕНИЕ - О НАС</h2>

                <p>
                    1.1. NCC (далее "Компания"), является лицензированным поставщиком услуг, который предоставляет
                    инвестиционные и дополнительные услуги, как они определяются в настоящем соглашении, через свою
                    электронную систему через Интернет (далее "Торговые платформы").
                </p>

                <p>
                    1.2. Ниже описаны условия, на которых компания NCC. ("NCC") предлагает вам, клиенту (далее "вы"
                    или "пользователь" или "клиент"), доступ к своему веб-сайту и использование своих услуг (далее
                    "Соглашение" или "Пользовательское соглашение" или "Соглашение об онлайн-услугах").
                </p>

                <p>
                    1.3. "NCC принадлежит и управляется компанией NCC Операционным адресом является: 125009, г.
                    Москва, Большой Кисловский переулок, д. 13 · 125009, г. Москва, Большой Кисловский переулок, д. 13.
                    Если у вас возникли вопросы относительно данного Соглашения, обратитесь в службу поддержки клиентов.
                    Вы должны прочитать, согласиться и принять все положения и условия, содержащиеся в настоящем
                    Пользовательском соглашении без изменений, включая те положения и условия, которые прямо изложены
                    ниже, и те, которые включены в него посредством ссылки, прежде чем вы сможете стать клиентом NCC
                    Продолжая посещать или использовать веб-сайт, вы соглашаетесь следовать положениям и условиям
                    настоящего Соглашения в той мере, в какой они могут быть применимы к вам.
                </p>

                <h2>2. СВЯЗЬ С НАМИ</h2>

                <p>
                    2.1. Вы можете общаться с нами в письменной форме, по электронной почте или другими электронными
                    средствами, или устно (включая телефон). Языком общения является русский и английский, и вы будете
                    получать
                    от нас документы и другую информацию на русском и английском языках. Однако, в случае необходимости
                    и для
                    вашего удобства, мы будем стараться общаться с вами на других языках. Принимая и соглашаясь с
                    условиями настоящего Соглашения, вы принимаете следующие условия и все политики, включенные в
                    официальный сайт компании
                </p>

                <p>
                    2.2. "Компания" может свободно использовать любую идею, концепцию, ноу-хау, технику или информацию,
                    содержащуюся в ваших сообщениях, в любых целях, включая, но не ограничиваясь, разработку и маркетинг
                    продуктов. Компания осуществляет мониторинг ваших сообщений для оценки качества получаемых вами
                    услуг,
                    соблюдения вами настоящего Соглашения, безопасности веб-сайта или по другим причинам. Вы
                    соглашаетесь,
                    что такая деятельность по мониторингу не дает вам права на какой-либо иск или иное право в отношении
                    того, каким образом Компания отслеживает ваши сообщения.
                </p>

                <h2>3. ПРАВО НА ЧЛЕНСТВО</h2>

                <p>
                    3.1. Услуги доступны и предназначены только для физических или юридических лиц, заключивших
                    юридически обязывающий договор в соответствии с законодательством, действующим в стране их
                    проживания. Без ограничения нижеуказанных условий, наши Услуги недоступны лицам, не достигшим 18
                    лет или не достигшим установленного законом возраста ("Несовершеннолетние"). Во избежание каких-либо
                    сомнений, мы отказываемся от любой ответственности за несанкционированное использование
                    несовершеннолетними наших Услуг любым способом или иным образом.
                </p>

                <p>
                    3.2. Не ограничивая вышеупомянутые положения, наши Услуги недоступны в местах, где их использование
                    незаконно, и Компания оставляет за собой право отказать и/или отменить доступ к своим Услугам любому
                    лицу по своему усмотрению.
                </p>

                <p>
                    3.3. Во избежание сомнений, возможность доступа к нашему сайту не обязательно означает, что наши
                    услуги и/или ваши действия через него являются законными в соответствии с законами, правилами или
                    директивами, относящимися к стране вашего проживания.
                </p>

                <h2>4. ОПРЕДЕЛЕНИЯ - ИНТЕРПРЕТАЦИЯ</h2>

                <p>
                    4.1. "Счет" означает личный торговый счет, который Клиент ведет в Компании и которому присвоен
                    определенный номер счета.
                </p>

                <p>4.2. "Коды доступа" означает имя пользователя и пароль, предоставленные Компанией Клиенту для доступа
                    к веб-сайту Компании.</p>

                <p>4.3. "Соглашение" означает настоящие Положения и условия для Услуг, предлагаемых Компанией.</p>

                <p>4.4. "Баланс" означает сумму, находящуюся от имени Клиента на его Клиентском счете в течение любого
                    периода времени.</p>

                <p>4.5. "Рабочий день" означает день, который не является субботой или
                    воскресеньем, государственным праздником в Белизе или любым другим праздником, который будет
                    объявлен
                    Компанией на ее веб-сайте.</p>

                <p>4.6. "Разрешение LSP" означает лицензию, полученную Компанией от IFSC,
                    в которую время от времени могут вноситься изменения и которая определяет инвестиционные и
                    дополнительные услуги, которые Компания имеет право предоставлять.</p>

                <p>4.7. "CFD" означает "контракт
                    на разницу цен" и представляет собой соглашение (контракт) на обмен разницы в стоимости определенной
                    валюты, товарной акции или индекса между моментом открытия контракта и моментом его закрытия.
                    Прибыль
                    или убытки получаются в зависимости от того, как изменяется цена базового инструмента по сравнению с
                    ценой на момент заключения контракта.</p>

                <p>4.8. "Исполнение" означает исполнение поручений клиентов на
                    торговой платформе Компании, где Компания выступает в качестве Агента по сделкам клиентов.</p>

                <p>4.9.
                    "Финансовые рынки" означает международные финансовые рынки, на которых курсы обмена финансовых
                    инструментов определяются в ходе многосторонней торговли.</p>

                <p>4.10. "Финансовые инструменты" означает любой из финансовых инструментов, предлагаемых Компанией,
                    которые определены как таковые в соответствии с действующим законодательством или нормативными
                    актами. Согласно лицензии Компании, таковыми
                    являются:
                </p>
                <ul>
                    <li>4.10.1. Ценные бумаги, подлежащие передаче</li>

                    <li>4.10.2. Инструменты финансового рынка</li>

                    <li>4.10.3. Доли в капитале коллективного инвестирования</li>

                    <li>4.10.4. Опционы, фьючерсы, свопы, соглашения о форвардных ставках и любые другие производные
                        контракты, касающиеся ценных бумаг, валют, процентных ставок или доходности, или других
                        производных инструментов, финансовых индексов или финансовых показателей, расчеты по которым
                        могут производиться в денежной форме.
                    </li>

                    <li>4.10.5. Опционы, фьючерсы, свопы, соглашения о форвардных ставках и любые другие производные
                        контракты, связанные с товарами, которые должны быть рассчитаны наличными или могут быть
                        рассчитаны наличными по выбору одной из сторон (кроме как по причине дефолта или другого события
                        расторжения).
                    </li>

                    <li>4.10.6. Опционы, фьючерсы, свопы и любые другие производные контракты, связанные с товарами,
                        которые могут быть физически рассчитаны, при условии, что они торгуются на регулируемом рынке
                        или/и MTF.
                    </li>

                    <li>4.10.7. Опционы, фьючерсы, свопы, форварды и любые другие производные контракты, связанные с
                        товарами, которые могут быть рассчитаны физически, не упомянутые в пункте выше, и не
                        предназначенные для коммерческих целей, которые имеют
                        характеристики других производных финансовых инструментов, с учетом того, являются ли они, среди
                        прочего, клиринговыми и расчетными через признанные клиринговые палаты или подлежат регулярному
                        требованию о внесении маржи.
                    </li>

                    <li>4.10.8. Производные инструменты для передачи кредитного риска.</li>

                    <li>4.10.9. Финансовые контракты на разницу.</li>

                    <li>4.10.10. Опционы, фьючерсы, свопы, соглашения о форвардных ставках и любые другие производные
                        контракты, относящиеся к климатическим переменным, фрахтовым ставкам, квотам на выбросы или
                        темпам инфляции или другой официальной экономической статистике, которые должны быть рассчитаны
                        наличными или могут быть рассчитаны наличными по выбору одной из сторон (иначе чем по причине
                        дефолта или другого события прекращения), а также любые другие производные контракты,
                        относящиеся к активам, правам, обязательствам, индексам и мерам, не упомянутым в настоящей
                        части, которые имеют характеристики других производных финансовых инструментов, принимая во
                        внимание, в частности, торгуются ли они на регулируемом рынке или MTF, проходят клиринг и
                        расчеты через признанные клиринговые палаты или подвергаются регулярным требованиям о внесении
                        маржи.
                    </li>
                </ul>

                <p>4.11. "Операционное (торговое) время Компании" означает период времени в течение рабочей недели,
                    когда торговый терминал Компании обеспечивает возможность совершения торговых операций. Компания
                    оставляет за собой право изменять этот период времени по своему усмотрению, уведомив об этом
                    Клиента.
                </p>

                <p>4.12. "Ордер" означает запрос/инструкцию, данную Клиентом Компании для открытия или закрытия позиции
                    на Счете Клиента.</p>

                <p>4.13. "Услуги" означают инвестиционные и дополнительные услуги, которые будут предоставляться
                    Компанией клиентам и регулируются настоящим Соглашением, как они описаны в пункте 5 настоящего
                    Соглашения.</p>

                <p>4.14. "Сделка" означает любой вид операции, подпадающей под действие настоящего
                    Соглашения, осуществляемой на торговом счете (счетах) Клиента, включая, но не ограничиваясь
                    Депозитом,
                    Снятием средств, Открытыми сделками, Закрытыми сделками и любой другой операцией с любым финансовым
                    инструментом.</p>

                <p>4.15. "Сделка" означает любой вид операции, подпадающей под действие настоящего
                    Соглашения, осуществляемой на торговом счете (счетах) Клиента, включая, но не ограничиваясь
                    Депозитом,
                    Снятием средств, Открытыми сделками, Закрытыми сделками и любой другой операцией с любым финансовым
                    инструментом.</p>

                <p>4.16. В настоящем Соглашении все слова, обозначающие только единственное число,
                    также включают множественное число, где применяются вышеупомянутые определения и наоборот, а слова,
                    обозначающие физических лиц, включают юридических лиц и наоборот. Слова, обозначающие любой пол,
                    включают все полы, и всякий раз, когда упоминаются термины "Пункты", "Разделы" и "Приложения", речь
                    идет
                    о пунктах, разделах и приложениях настоящего Соглашения.</p>

                <p>4.17. Заголовки разделов используются только для облегчения ссылок и не влияют на их толкование.
                    Ссылки на любое правило или постановление будут рассматриваться как ссылки на это правило или
                    постановление, поскольку они могут быть время от времени изменены или заменены или, аналогично,
                    расширены, вновь приняты или изменены.
                </p>

                <h2>5. ОКАЗАНИЕ УСЛУГ</h2>

                <p>5.1. Ниже перечислены инвестиционные услуги, которые Компания имеет право предоставлять в
                    соответствии с разрешением LSP, регулируемые настоящим Соглашением</p>

                <p>5.2. Следует уточнить и
                    отметить, что Компания работает только на основе исполнения и не консультирует по существу
                    конкретных
                    сделок или их налоговых последствий.</p>

                <p>5.3. Вы принимаете на себя всю ответственность в отношении
                    любой инвестиционной стратегии, сделки или инвестиций, налоговых расходов и за любые последствия,
                    вызванные любой совершенной вами сделкой. Компания не несет ответственности, и вы не должны
                    полагаться
                    на Компанию в отношении вышеупомянутого.</p>

                <p>5.4. Если Компания предоставляет общие торговые
                    рекомендации, комментарии по рынку или другую информацию в своих информационных материалах и/или на
                    своем веб-сайте</p>

                <p>5.5. Данная информация является сопутствующей вашим дилерским отношениям с Компанией. Она
                    предоставляется исключительно для того, чтобы вы могли принимать собственные инвестиционные решения,
                    и не является инвестиционной консультацией;</p>

                <p>5.6. Если документ содержит ограничение в
                    отношении лица или категории лиц, для которых этот документ предназначен или которым он
                    распространяется, вы соглашаетесь, что не будете передавать его такому лицу или категории лиц;</p>

                <p>5.7. Компания не дает никаких гарантий или заверений относительно точности или полноты такой
                    информации или относительно налоговых последствий любой Сделки;</p>

                <p>5.8. Вы принимаете, что до его
                    отправки Компания могла использовать информацию, на которой он основан. Компания не делает заявлений
                    относительно времени получения вами информации и не может гарантировать, что вы получите такую
                    информацию в то же время, что и другие клиенты. Любые опубликованные исследовательские отчеты или
                    рекомендации могут появляться в одной или нескольких информационных сервисах.</p>

                <p>5.9. Время работы Компании - с 00:01 GMT в воскресенье до 23:59 GMT в пятницу, за исключением
                    праздничных дней, о которых будет объявлено на веб-сайте Компании. Компания оставляет за собой право
                    приостанавливать или изменять часы работы по своему усмотрению, и в этом случае ее веб-сайт будет
                    незамедлительно обновлен, чтобы вы были проинформированы соответствующим образом.
                </p>

                <h2>6. ИНФОРМАЦИЯ И ТРЕБОВАНИЯ ДЛЯ ОТКРЫТИЯ СЧЕТА</h2>

                <p>6.1. Когда вы регистрируетесь для получения вышеупомянутых Услуг, Компания попросит вас предоставить
                    определенную идентифицирующую информацию. Вы подтверждаете свое желание поделиться с Компанией
                    определенной частной информацией, которую она использует для подтверждения вашей личности. Эта
                    информация собирается в соответствии с нашими строгими процедурами проверки, которые используются
                    для предотвращения международных операций по отмыванию денег и обеспечения безопасности и
                    сохранности торговой деятельности наших клиентов на протяжении всего времени, и является предметом
                    "Политики конфиденциальности" Компании. Каждый клиент имеет право иметь один (1) счет, в случае если
                    клиент открывает более одного (1) счета, Компания будет иметь право переводить деньги или сделки с
                    одного счета на другой, даже если такой перевод потребует закрытия любых сделок на счете, с которого
                    был осуществлен перевод.</p>

                <p>6.2. Если вы регистрируетесь, как юридическое лицо, настоящим вы заявляете, что у вас есть полномочия
                    связать юридическое лицо настоящим Соглашением. Компания будет бережно относиться к информации,
                    которую вы ей доверяете, в соответствии с раскрытием, которое она предоставляет в процессе
                    регистрации и в своей Политике конфиденциальности.</p>

                <p>6.3. Регистрируясь в Компании, вы подтверждаете и соглашаетесь, что даете согласие на использование
                    всей или части предоставленной вами информации, касающейся вашего торгового счета, операций, которые
                    вы совершаете через него, и взаимодействий, которые вы осуществляете с Компанией от имени Компании.
                    Все взаимодействия, которые вы осуществляете с Компанией, будут храниться Компанией в целях ведения
                    учета, как того требует Закон, и могут быть использованы Компанией в случаях возникновения споров
                    между вами и Компанией или по запросу IFSC или любого другого компетентного органа.
                </p>

                <h2>7. ГАРАНТИИ ОТ ЛИЦА КЛИЕНТА</h2>

                <p>7.1. Вы заявляете, подтверждаете и гарантируете, что любые средства, переданные Компании для торговых
                    целей, принадлежат исключительно вам и свободны от любого залога, обременения. Кроме того, любые
                    средства, переданные вами Компании, никоим образом не являются прямыми или косвенными доходами от
                    какого-либо незаконного действия или бездействия или продуктом какой-либо преступной
                    деятельности.</p>

                <p>7.2. Вы действуете от своего имени, а не как представитель или доверенное лицо
                    любого третьего лица, если вы не представили, к удовлетворению Компании, документ и/или
                    доверенность,
                    позволяющие вам действовать в качестве представителя и/или доверенного лица любого третьего
                    лица.</p>

                <p>7.3. Вы соглашаетесь и понимаете, что в случае, если у Компании будут доказательства, достаточные для
                    того, чтобы указать, что определенные суммы, классифицированные выше, полученные вами, являются
                    доходами от незаконных действий или продуктами любой преступной деятельности и/или принадлежат
                    третьей стороне, Компания оставляет за собой право вернуть эти суммы отправителю, которым являетесь
                    вы или бенефициарный владелец юридического лица. Кроме того, вы также соглашаетесь и понимаете, что
                    Компания может отменить любые операции, совершенные на вашем торговом счете, и расторгнуть настоящее
                    соглашение. Компания оставляет за собой право предпринять любые юридические действия против вас для
                    покрытия и возмещения убытков в случае такого события и может потребовать возмещения любых убытков,
                    причиненных компании вами в результате такого события.</p>

                <p>7.4. Вы заявляете, что вам более 18
                    (восемнадцати) лет, если вы физическое лицо, или что вы обладаете полной правоспособностью, в случае
                    если вы юридическое лицо, для заключения настоящего Соглашения.</p>

                <p>7.5. Вы понимаете и принимаете,
                    что все операции, связанные с торговлей любым из Финансовых инструментов, будут осуществляться
                    только
                    через Торговые платформы, предоставляемые Компанией, и Финансовые инструменты не могут быть переданы
                    на
                    любую другую Торговую платформу.
                </p>

                <p>7.6. Вы гарантируете подлинность и действительность любого документа, переданного Клиентом Компании.
                    Вы понимаете и принимаете, что Компания не может предоставить вам никаких юридических консультаций
                    или гарантий в отношении использования вами Услуг, и Компания не делает никаких заявлений
                    относительно законности Услуг в вашей юрисдикции.
                </p>

                <h2>8. ЭЛЕКТРОННАЯ ТОРГОВЛЯ</h2>

                <p>8.1. Компания предоставит вам Коды доступа для получения онлайн-доступа к веб-сайту Компании и/или
                    торговым платформам, что позволит вам размещать заказы на любой финансовый инструмент, доступный в
                    Компании, и заключать сделки с Компанией. Кроме того, вы сможете торговать на Торговых платформах
                    Компании и через Компанию с использованием персонального компьютера, смартфона или любого другого
                    аналогичного устройства, подключенного к Интернету. При этом вы понимаете, что Компания может по
                    своему абсолютному усмотрению прекратить ваш доступ к системам Компании в целях защиты интересов
                    Компании и ваших интересов, а также для обеспечения эффективности и результативности систем.</p>
                <p>8.2. Вы соглашаетесь, что будете хранить Коды доступа в безопасном месте, выбранном по вашему
                    усмотрению, и не будете раскрывать их никакому другому лицу. Вы не будете предпринимать и избегать
                    любых действий, которые могут привести к несанкционированному доступу или использованию Торговых
                    платформ</p><p>8.3. Вы соглашаетесь не пытаться злоупотреблять Торговыми платформами в попытке
                получить незаконную прибыль или пытаться получить прибыль, используя преимущества задержки сервера, или
                применяя такие методы, как манипулирование ценами, торговля с запаздыванием, манипулирование
                временем.</p><p>8.4. Вы несете ответственность за все действия или бездействие, которые происходят на
                Веб- сайте в результате использования вашей регистрационной информации. Если вы считаете, что кто-то
                использовал или использует вашу регистрационную информацию, имя пользователя или пароль для доступа к
                любой Услуге без вашего разрешения, вы должны немедленно уведомить об этом Компанию. Вы должны приложить
                все возможные усилия, чтобы сохранить Коды доступа в тайне и известными только вам, и вы будете нести

                ответственность за любые Ордера, полученные Компанией через ваш торговый Счет по вашим Кодам доступа.
                Кроме того, любые Ордера, полученные Компанией, будут рассматриваться, как полученные от вас. В случаях,
                когда третье лицо назначается уполномоченным представителем, чтобы действовать от вашего имени, вы
                будете нести ответственность за все Распоряжения, отданные через и по Паролю счета представителя.
            </p><p>8.5. Вы обязаны следить за состоянием своего Счета и немедленно уведомить Компанию, если до вас
                дойдет, что ваши Коды доступа утеряны или используются неавторизованной третьей стороной. Кроме того, вы
                соглашаетесь немедленно уведомить Компанию, если вам станет известно о неполучении вами сообщения о
                приеме и/или исполнении ордера, точного подтверждения исполнения, любой информации об остатках на вашем
                Счете, ордерах или истории операций, а также в случае получения подтверждения ордера, который вы не
                размещали.</p><p>8.6. Вы признаете, что Компания может принять решение не предпринимать действий на
                основании Распоряжений, переданных Компании с помощью электронных средств, отличных от Распоряжений,
                переданных Компании с помощью заранее определенных электронных средств, таких как Торговая платформа, и
                Компания не несет ответственности перед Клиентом за непринятие действий на основании таких
                Распоряжений.</p><p>8.7. Вы соглашаетесь использовать программное обеспечение, разработанное третьими
                лицами, включая, но не ограничиваясь перечисленным выше, программное обеспечение браузера, которое
                поддерживает протоколы безопасности данных, совместимые с протоколами, используемыми Компанией. Более
                того, вы соглашаетесь следовать процедуре доступа (Логин) Компании, поддерживающей такие протоколы.
            </p><p>8.8. Компания не несет ответственности в случае несанкционированного доступа третьих лиц к
                информации, включая, но не ограничиваясь, электронными адресами и/или персональными данными, в
                результате обмена этими данными между вами и Компанией и/или любой другой стороной с использованием
                Интернета или других доступных сетей или электронных средств.</p><p>8.9. Компания не несет
                ответственности за любые отключения электроэнергии или сбои, препятствующие использованию системы и/или
                Торговой платформы, и не может быть ответственна за невыполнение каких-либо обязательств по настоящему
                Соглашению из-за сбоев сетевого подключения или электроэнергии.
            </p><p>8.10. Компания также оставляет за собой право попросить вас дать инструкции относительно ваших
                операций другими способами, которые она сочтет необходимыми</p><p>8.11. Компания не несет
                ответственности за возможный ущерб, который вы можете понести в результате ошибок передачи, технических
                сбоев, неполадок, незаконного вмешательства в работу сетевого оборудования, перегрузок сети, вирусов,
                системных ошибок, задержек в исполнении, злонамеренного блокирования доступа третьими лицами, сбоев,
                перебоев в работе интернета или других недостатков со стороны интернет-провайдеров. Вы признаете, что
                доступ к электронным системам/торговым платформам может быть ограничен или недоступен из-за таких
                системных ошибок, и что Компания оставляет за собой право после уведомления Вас приостановить доступ к
                электронным системам/торговым платформам по этой причине.</p><p>8.12. Компания имеет право в
                одностороннем порядке и с немедленным вступлением в силу приостановить или навсегда лишить вас
                возможности использовать любой Электронный сервис или любую его часть без уведомления, если Компания
                сочтет это необходимым или целесообразным, например, в связи с несоблюдением вами Применимых правил,
                нарушением любых положений настоящего Соглашения, наступлением События неисполнения
                обязательств (как определено в разделе 23 настоящего Соглашения), сетевыми проблемами, сбоями в
                электроснабжении, для технического обслуживания или для защиты вас в случае

                нарушения безопасности. Кроме того, использование услуги может быть прекращено автоматически при
                прекращении действия (по любой причине) любой лицензии, предоставленной Компании, которая относится к
                данной услуге; или настоящего Соглашения. Использование услуги может быть немедленно прекращено, если
                услуга изымается любым рынком или Компания обязана изъять средство для соблюдения Применимых правил.
            </p>

                <h2>9. ФИНАНСОВАЯ ИНФОРМАЦИЯ</h2>

                <p>9.1. Через одну или несколько своих Услуг Компания предоставляет вам широкий спектр финансовой
                    информации, которая генерируется внутри компании, агентами, поставщиками или партнерами ("Сторонние
                    поставщики"). Это включает, но не ограничивается данными финансового рынка, котировками и новостями,
                    мнениями аналитиков и исследовательскими отчетами, графиками и данными ("Финансовая
                    информация").</p><p>9.2. Финансовая информация, представленная на сайте Компании, не является
                преднамеренной инвестиционной консультацией. Компания и ее сторонние поставщики не гарантируют точность,
                своевременность, полноту или правильную последовательность финансовой информации, а также результаты
                использования вами этой финансовой информации. Финансовая информация может быстро стать ненадежной по
                различным причинам, включая, например, изменения рыночных условий или экономических обстоятельств.</p>
                <p>9.3. Вы несете ответственность за проверку достоверности информации на сайте Компании и ее
                    соответствия вашим потребностям. Мы исключаем всякую ответственность за любые претензии, ущерб или
                    потери любого рода, вызванные информацией, содержащейся на сайте Компании или на которую ссылается
                    сайт Компании.
                </p>

                <h2>10. ОРДЕРЫ – ИНСТРУКЦИИ И ОСНОВА СДЕЛОК</h2>

                <p>10.1. Вы можете разместить Ордер через торговую платформу Компании. После получения Компанией ваших
                    инструкций или Приказов они не могут быть отменены, за исключением письменного согласия Компании,
                    которое может быть дано по ее единоличному и абсолютному усмотрению.</p><p>10.2. Вы размещаете свой
                рыночный запрос по ценам, которые видите на своем
                терминале/платформе, и начинается процесс исполнения. В связи с высокой волатильностью рынка, а также
                интернет-связью между терминалом клиента и сервером, цены, запрашиваемые клиентом, и текущая рыночная
                цена могут меняться в течение этого процесса.
            </p><p>10.3. Вы имеете право использовать доверенность, чтобы уполномочить третье лицо (представителя)
                действовать от вашего имени во всех деловых отношениях с Компанией. Доверенность должна быть
                предоставлена в Компанию в сопровождении всех документов, удостоверяющих личность представителя. Если
                срок действия доверенности не указан, она будет считаться действительной до письменного прекращения ее
                действия с вашей стороны.</p><p>10.4. Компания прилагает все разумные усилия для быстрого выполнения
                любого поручения, но, принимая ваши поручения, Компания не заявляет и не гарантирует, что такое
                поручение будет возможно выполнить или что выполнение будет возможно в соответствии с вашими
                инструкциями. В случае, если Компания столкнется с какими-либо существенными трудностями при выполнении
                заказа от вашего имени, например, в случае закрытия рынка

                и/или из-за неликвидности финансовых инструментов и других рыночных условий, Компания незамедлительно
                уведомит вас.
            </p>
                <p>10.5. Ордера могут быть размещены, исполнены, изменены или удалены только в течение операционного
                    (торгового) времени и сохраняют силу до следующей торговой сессии. Ваш Ордер действителен и
                    соответствует типу и времени действия данного Ордера, как указано. Если время действия Ордера не
                    указано, он действует бессрочно.</p><p>10.6. Компания будет записывать телефонные разговоры без
                предварительного предупреждения (если это не требуется в соответствии с Применимыми нормативными
                актами), чтобы обеспечить надлежащую запись существенных условий Сделки и/или заказа, размещенного
                клиентом, и/или любой другой существенной информации, относящейся к сделке. Такие записи будут являться
                собственностью Компании и будут приниматься вами в качестве доказательства ваших заказов или
                инструкций.</p><p>10.7. Компания может потребовать от вас ограничить количество открытых позиций,
                которые вы можете иметь в Компании в любое время, и Компания может по своему усмотрению закрыть одну или
                несколько сделок, чтобы обеспечить соблюдение таких лимитов позиций. Лимиты позиций будут заранее
                сообщены вам либо через веб-сайт Компании, либо через торговые платформы.</p><p>10.8. Если какой-либо
                базовый актив финансового инструмента становится подверженным определенному риску, приводящему к
                прогнозируемому падению стоимости, Компания оставляет за собой право вывести конкретный финансовый
                инструмент с торговой платформы Компании.
            </p>
                <p>
                    10.9. Компания имеет право устанавливать контрольные лимиты в отношении ваших ордеров по своему
                    собственному и абсолютному усмотрению. Такие лимиты могут быть изменены, удалены или добавлены:
                </p>
                <ul>
                    <li>10.9.1. контроль над максимальной суммой и размером ордера;</li>
                    <li>10.9.2. средства контроля над электронными системами и/или торговыми платформами для проверки,
                        например, вашей личности во время получения заявки;
                    </li>
                    <li>10.9.3. любые другие ограничения, параметры или средства контроля, которые Компания может
                        посчитать необходимыми для реализации в соответствии с Применимыми нормативными актами.
                    </li>
                </ul>
                <p>10.10. Могут существовать ограничения по количеству сделок, которые вы можете заключать в любой день,
                    а также по общей стоимости этих сделок. Вы признаете, что некоторые рынки устанавливают ограничения
                    на типы ордеров, которые могут быть напрямую переданы в их электронные торговые системы. Такие типы
                    ордеров иногда называют синтетическими ордерами. Передача синтетических ордеров на рынок зависит от
                    точного и своевременного получения цен или котировок от соответствующего рынка или поставщика
                    рыночных данных. Вы признаете, что рынок может отменить синтетический ордер при модернизации своих
                    систем, торговые платформы могут отказаться от записи такого ордера, и вы вводите такие ордера на
                    свой страх и риск. Вы должны обратиться на веб-сайт Компании для получения подробной информации об
                    ограничениях/лимитах, налагаемых на сделки, совершаемые через ее электронные системы и/или торговые
                    платформы.</p>
                <p>10.11. Компания предпринимает все разумные шаги для достижения наилучших результатов для своих
                    Клиентов. Политика наилучшего исполнения Компании содержит общий обзор того, как исполняются ордера,
                    а также ряд других факторов, которые могут повлиять на исполнение финансового инструмента. Вы
                    подтверждаете и соглашаетесь с тем, что

                    прочитали и поняли "Политику наилучшего исполнения", которая была предоставлена вам в процессе
                    регистрации и размещена на сайте Компании.
                </p><p>10.12. Вы соглашаетесь, что Компания может исполнить ордер от вашего имени вне регулируемого
                рынка и/или MTF, и что Политика Компании по исполнению ордеров не будет применяться, когда вы размещаете
                конкретное распоряжение. Местом исполнения всех распоряжений клиентов будет должным образом
                уполномоченный маркет-мейкер.</p>

                <h2>11. ПОДТВЕРЖДЕНИЯ</h2>

                <p>11.1. Подтверждения всех сделок, совершенных на вашем Торговом счете в торговый день, будут доступны
                    через ваш онлайн-счет на Торговой платформе сразу после совершения сделки. Вы несете ответственность
                    за уведомление Компании, если какие-либо подтверждения являются неверными. Подтверждения, при
                    отсутствии явной ошибки, будут окончательными и обязательными для вас, если вы не заявите о своем
                    несогласии в письменном виде в течение 7 (семи) рабочих дней. Вы можете запросить получение выписки
                    по счету ежемесячно или ежеквартально по электронной почте, направив такой запрос в Компанию, но
                    Компания не обязана предоставлять вам выписку по счету в бумажном виде. Выписка по счету может быть
                    предоставлена за счет клиента.</p><p>11.2. Вы имеете право уполномочить третье лицо давать
                инструкции и/или ордера Компании или решать любые другие вопросы, связанные с настоящим Соглашением, при
                условии, что Вы письменно уведомили Компанию о том, что такое право будет осуществляться третьим лицом,
                и что это лицо одобрено Компанией и выполняет все условия Компании для разрешения этого.</p><p>11.3. В
                случае, если вы уполномочили третье лицо, как указано выше, существует договоренность, что в случае,
                если вы захотите прекратить полномочия, вы несете полную ответственность за уведомление Компании о таком
                решении в письменном виде. В любом другом случае Компания будет считать, что авторизация все еще
                продолжается, и продолжит принимать инструкции и/или Приказы, данные уполномоченным лицом от вашего
                имени.</p>

                <h2>12. ЦЕНОВАЯ ПОЛИТИКА</h2>

                <p>12.1. Компания предложит цены, по которым она готова работать с вами. Сохранить где:</p>
                <ul>
                    <li>12.1.1. Компания осуществляет любое из своих прав на закрытие сделки;</li>
                    <li>12.1.2. Сделка закрывается автоматически</li>
                </ul>
                <p>12.2. Вы
                    сами решаете, хотите ли вы совершить сделку по цене, указанной Компанией. Цены Компании определяются
                    Компанией в порядке, изложенном в прилагаемых условиях.</p>
                <p>12.3. Каждая цена действует и может быть использована в инструкции по сделке до наступления более
                    раннего из двух моментов: времени истечения срока ее действия и времени, если таковое наступит,
                    когда она будет отозвана Компанией иным образом. По истечении этого срока цена не может быть
                    использована в инструкции по сделке. Каждая цена может быть использована в дилинговой инструкции для
                    сделки с основной суммой, не превышающей максимальную, установленную Компанией. Вы признаете, что
                    эти цены и максимальные суммы могут отличаться от цен и максимальных сумм, предоставляемых другим
                    клиентам Компании, и могут быть отозваны или изменены без уведомления. Компания может по своему
                    усмотрению и без предварительного уведомления немедленно

                    прекратить предоставление цен в некоторых или всех валютных парах и для некоторых или всех дат в
                    любое время.
                </p><p>12.4. Когда Компания котирует цену, рыночные условия могут измениться в период между отправкой
                Компанией котировки и моментом исполнения вашего ордера. Такое движение может быть как в вашу пользу,
                так и против нее. Цены, которые могут котироваться и/или торговаться время от времени другими
                маркет-мейкерами или третьими лицами, не применяются к сделкам между Компанией и вами.</p>

                <h2>13. ОТКАЗ ОТ ВЫПОЛНЕНИЯ ОРДЕРОВ</h2>

                <p>13.1. Компания имеет право в любое время и по любой причине, без уведомления и/или объяснения причин,
                    отказаться, по своему усмотрению, от выполнения любого Ордера, включая, без ограничения, следующие
                    случаи:</p>
                <ul>
                    <li>13.1.1. Если вы не предоставите Компании любые документы, запрошенные у вас в целях
                        идентификации клиента или по любой другой причине.
                    </li>
                    <li>13.1.2. Если компания подозревает или имеет опасения, что представленные документы могут быть
                        ложными или поддельными.
                    </li>
                    <li>13.1.3. Если у вас недостаточно средств на счету.</li>
                    <li>13.1.4. Если Компания получает информацию о том, что ваша кредитная или дебетовая карта (или
                        любой другой используемый способ оплаты) была утеряна или украдена.
                    </li>
                    <li>13.1.5. Если Компания считает, что существует риск возврата денег.</li>
                    <li>13.1.6. Если у Компании есть достаточные основания подозревать, что исполнение Ордера
                        является частью попытки манипулирования рынком, торговли внутренней информацией, связано с
                        деятельностью по отмыванию денег или если оно может потенциально каким- либо образом повлиять на
                        надежность, эффективность или бесперебойную работу Торговой платформы.
                    </li>
                    <li>13.1.7. Если у вас нет достаточных свободных средств, депонированных в Компании, или на вашем
                        банковском счете для оплаты ордера вместе с соответствующими сборами и комиссиями, необходимыми
                        для осуществления сделки в Торговой платформе. В случае, если Компания отказывается исполнять
                        ордер, такой отказ не влияет на любое
                        обязательство, которое вы можете иметь перед Компанией, или любое требование, которое Компания
                        может иметь против вас или ваших активов.

                    </li>
                    <li>13.1.8. Если распоряжение является результатом использования внутренней конфиденциальной
                        информации (инсайдерской торговли).
                    </li>
                </ul>

                <p>13.2. Подразумевается, что любой отказ Компании выполнить любой ордер не влияет ни на какие
                    обязательства, которые вы можете иметь перед Компанией, ни на какие требования, которые Компания
                    может иметь против вас или ваших активов.</p><p>13.3. Вы заявляете, что не будете сознательно
                отдавать Компании какие-либо распоряжения или указания, которые могут спровоцировать принятие Компанией
                мер в соответствии с пунктом выше.</p>

                <h2>14. АННУЛИРОВАНИЕ СДЕЛОК</h2>

                <p>14.1. Компания имеет право отменить сделку, если у нее есть достаточные основания/доказательства
                    полагать, что произошло одно из следующих событий:</p>
                <ul>
                    <li>14.1.1. Мошенничество/незаконные действия привели к совершению сделки,</li>
                    <li>14.1.2. Ордера, размещенные по ценам, которые были отображены в результате системных ошибок или
                        сбоев в работе систем Компании или ее сторонних поставщиков услуг.
                    </li>
                    <li>14.1.3. Компания не выполнила ваши указания.</li>
                    <li>14.1.4. Сделка была совершена в нарушение положений настоящего Соглашения.
                    </li>
                </ul>

                <h2>15. УРЕГУЛИРОВАНИЕ СДЕЛОК</h2>

                <p>15.1. Компания приступает к расчетам по всем сделкам после их исполнения. Приобретение финансового
                    контракта завершено, когда финансовый контракт настроен, премия (или маржа, в зависимости от
                    обстоятельств) рассчитана и оплата проверена. Вы соглашаетесь нести полную и личную ответственность
                    за надлежащее исполнение каждой сделки, заключенной по вашему счету в компании.</p>

                <h2>16. ПОЛИТИКА В ОТНОШЕНИИ БОНУСОВ И АКЦИЙ</h2>

                <p>16.1. Компания предлагает ряд привлекательных бонусных программ своим новым и существующим Клиентам,
                    активировавшим свой торговый счет. Бонусы и/или финансовые награды и/или акции ("Бонусы"),
                    начисляемые Клиентам, являются частью рекламной программы Компании. Эти Бонусы имеют ограниченное
                    время действия, и их условия могут быть изменены после письменного уведомления Клиентов о внесении
                    изменений в существенные части условий. Перед принятием любого предложения Бонусов, Клиентам будет
                    предоставлена возможность рассмотреть условия, связанные с каждой Бонусной
                    схемой, чтобы полностью понять, как работает схема, а также ее T & C и дать свое согласие в
                    отношении принятия любых изменений, в случае если клиент не предоставит Компании никаких отзывов или
                    советов об обратном, в течение семи (7) дней, Компания будет считать, что клиент согласен и/или
                    принимает содержание изменений
                </p><p>16.2. Клиенты не обязаны принимать какие-либо Бонусы, предлагаемые Компанией; все Бонусы являются
                необязательными. Клиенты могут решить не принимать Бонус. В этих обстоятельствах Клиенты не будут
                связаны условиями, относящимися к Бонусу. Принимая предлагаемые Бонусы, Клиенты будут связаны
                руководящими принципами, положениями и условиями бонусных программ, при условии, что они полностью
                поняли и согласились с условиями данного соглашения.</p>
                <p>16.3. Различные бонусные программы и акции
                    предоставляются в случайных мероприятиях и будут добавлены на торговый счет клиента в одной из
                    следующих
                    форм:</p>
                <ul>
                    <li>16.3.1. Отложенный бонус - бонус будет добавлен в поле трейдера "отложенный бонус" и будет
                        добавлен к фактическому балансу счета после того, как будут проведены определенные объемы
                        торговли. Бонус не может быть использован, пока не будет выполнен определенный объем в
                        соответствии с конкретной бонусной схемой.
                    </li>
                </ul>
                <p>16.4. Для отслеживания объема трейдера и обнаружения бонуса и его соответствующего статуса,
                    трейдеру предлагается проверить раздел "Отчеты" на главной странице после входа в систему. Для этого
                    трейдер может нажать кнопку "Отчеты" в правом верхнем углу страницы, затем на вкладке "Сделки" в
                    разделе
                    "Объем" появятся отчеты о бонусах. Трейдер имеет возможность просматривать бонусный отчет, который
                    отслеживает объем на ежедневной основе.</p><p>16.5. Необходимый объем, который должен быть достигнут
                для
                снятия бонуса, рассчитывается с помощью суммы бонуса, умноженной на ставку в пределах 100. Торговый
                объем - это подсчет сделок трейдера, независимо от результата. В некотором смысле, это мониторинг
                торговой активности трейдера.</p><p>16.6. Например: вам предоставляется отложенный приветственный бонус
                в размере 25% на ваш депозит. Если вы внесли 300 долларов, ваш отложенный бонус составляет 75 долларов.
                Требование к объему торговли составляет, например 40 раз по $75, что равно $3 000. Как только вы
                наторгуете объем в $3,000, $75 будут переведены на ваш баланс и доступны для любых нужд.
            </p><p>16.7. Здесь следует упомянуть, что принятие и получение бонуса может оказаться весьма приятным. Из-за
                размера бонуса, который иногда может значительно улучшить состояние счета трейдера. В некоторых случаях
                трейдеры склонны не использовать бонус, и тратить его весь сразу. Поэтому мы просим трейдера обращать
                внимание на состояние счета и призываем его инвестировать бонусную сумму с осторожностью и
                ответственностью.</p><p>16.8. Все дополнения Компании к условиям бонуса являются окончательными.
            </p>
                <p>16.9. Настоящим Клиентам предлагается использовать бонус Компании законным и обоснованным образом, в
                    соответствии с условиями настоящего соглашения. Компания оставляет за собой право отменить и даже
                    закрыть счет трейдера, если она подозревает любую форму злоупотребления или неправильного
                    использования бонуса трейдером, что включает причины, указанные в разделе 23.</p><p>16.10. Компания
                должна единолично сделать вывод и принять решение о том, злоупотребляли ли клиенты и/или неправомерно
                использовали ее платформу и/или ее бонусы.</p><p>16.11. Все бонусы и льготы должны быть использованы в
                соответствии с заранее установленным периодом действия предложения.</p>
                <p>16.12. В случае злоупотребления и/или неправильного использования условий бонуса и, Компания имеет
                    право отозвать бонус. В этом случае, решения компании являются окончательными. Любое изменение
                    условий предоставления бонуса может быть отменено компанией, а затем отображено на ее веб-сайте.</p>
                <p>16.13. Согласие на получение бонуса и его зачисление на счет трейдера обязывает трейдера соблюдать
                    условия бонуса здесь и далее.</p>

                <h2>17. СРЕДСТВА КЛИЕНТОВ</h2>

                <p>17.1. Принадлежащие вам средства, которые будут использоваться для торговых целей, будут храниться на
                    счете в любом банке или финансовом учреждении, используемом для приема средств, который Компания
                    будет указывать время от времени, и будут храниться на имя Компании. Подразумевается, что Компания
                    может хранить средства от вашего имени в любом банке. Правовой и нормативный режим, применяемый к
                    любому такому банку, может отличаться от правового и нормативного режима в Белизе, и в случае
                    банкротства или любых

                    других аналогичных событий в отношении этого банка, к вашим средствам может быть применен иной
                    режим, чем тот, который применялся бы, если бы средства хранились в банке на счете в Белизе.
                    Компания не несет ответственности за неплатежеспособность, действия или бездействие любой третьей
                    стороны, упомянутой в данном пункте.
                </p>

                <p>17.2. Принято считать, что любая сумма, выплачиваемая Компанией вам, выплачивается непосредственно
                    вам на банковский счет, бенефициарным владельцем которого являетесь вы. Запросы на перевод средств
                    обрабатываются Компанией в сроки, указанные на официальном сайте Компании, а время, необходимое для
                    зачисления на ваш личный счет, зависит от поставщика вашего банковского счета.</p>

                <p>17.3. Компания сохраняет право на зачисление и может по своему усмотрению, время от
                    времени и без вашего разрешения, зачислить любые суммы, удерживаемые от имени и/или в кредит вас, в
                    счет вашего обязательства перед Компанией. Если иное не согласовано в письменной форме между
                    Компанией и вами, настоящее Соглашение не порождает прав на кредитные средства.
                </p><p>17.4. Вы имеете право снять средства, которые не используются для маржинального покрытия,
                свободные от каких-либо обязательств с Вашего счета без закрытия указанного счета.</p><p>17.5. Компания
                оставляет за собой право отклонить запрос на вывод средств, если запрос не соответствует определенным
                условиям, указанным в настоящем Соглашении, или задержать обработку запроса, если он не удовлетворен по
                предоставленной полной документации.</p><p>17.6. В ваши условия входит, что в случае снятия средств с
                вашего торгового счета на указанный вами банковский счет вы будете оплачивать любые возникающие
                банковские комиссии. Вы несете полную ответственность за платежные реквизиты, которые вы предоставили
                Компании, и Компания не несет никакой ответственности, если вы предоставили ложные или неточные
                банковские реквизиты.</p>
                <p>17.7. Вы соглашаетесь, что любые суммы, отправленные вами на банковские счета Компании, будут
                    зачислены на ваш торговый счет на дату поступления платежа и за вычетом любых сборов/штрафов,
                    взимаемых поставщиками банковских счетов или любыми другими посредниками, участвующими в процессе
                    такой транзакции. Для того чтобы Компания могла принять любой депозит от вас, необходимо проверить
                    идентификацию отправителя и убедиться, что лицо, вносящее средства, - это вы. Если эти условия не
                    выполняются, Компания оставляет за собой право вернуть чистую сумму, внесенную на депозит с помощью
                    метода, использованного вкладчиком.</p><p>17.8. Компания оставляет за собой право отказать в выводе
                средств с использованием определенного способа оплаты и предложить другой способ оплаты, при котором вам
                необходимо заполнить новый запрос на вывод средств. В случае, если Компания не полностью удовлетворена
                документацией, предоставленной в связи с запросом на вывод средств, Компания может запросить
                дополнительную документацию, и если запрос не будет удовлетворен, Компания может отменить запрос на
                вывод средств и внести средства обратно на ваш торговый счет.</p><p>17.9. При подаче заявки на вывод
                средств, Компании может потребоваться до 5 (пяти) рабочих дней для обработки заявки. Когда ваша заявка
                будет одобрена, пожалуйста, подождите максимум 10 (десять) дополнительных дней, прежде чем увидеть
                средства на своем счету. - в случаях задержек с возвратом средств на кредитную карту, которые не в нашей
                власти, мы обязаны провести расследование и обеспечить принятие всех мер для быстрого решения
                проблемы.</p>
                <p>17.10. В случае, если любая сумма, поступившая на банковские счета, будет аннулирована поставщиком
                    банковского счета в любое время и по любой причине, Компания немедленно аннулирует соответствующий
                    депозит с вашего торгового счета и далее оставляет за собой право аннулировать любые другие виды
                    операций, совершенных после даты аннулирования соответствующего депозита. Подразумевается, что эти
                    действия могут привести к отрицательному балансу на всех или любом из ваших торговых счетов.</p>
                <p>17.11. Вы соглашаетесь отказаться от любых своих прав на получение процентов, начисленных на
                    средства, находящиеся на банковском счете, на котором хранятся ваши средства.</p>
                <p>17.12. МИНИМАЛЬНЫЕ СУММЫ ВЫВОДА СРЕДСТВ</p>
                <ul>
                    <li>17.12.1. Когда вы снимаете средства со своего счета в NCC минимальная сумма снятия составляет
                        1,00$. Точный минимум будет зависеть от номинала
                        валюты клиентского счета.
                    </li>
                </ul>
                <p>17.13. КОМИССИЯ ЗА СНЯТИЕ СРЕДСТВ</p>
                <ul>
                    <li>17.13.1. Трейдеры, являющиеся участниками наших Платинового, Черного и VIP счетов, получают
                        первый вывод средств бесплатно. Точные сборы и комиссии будут зависеть от номинала валюты
                        клиентского счета.
                    </li>
                    <li>17.13.2. *Комиссии по снятию денег с банковского перевода будут возложены на бенефициара.</li>
                </ul>

                <h2>18. ПРОЦЕДУРА "НЕАКТИВНОГО СЧЕТА"</h2>

                <p>18.1. Вы признаете и подтверждаете, что любой торговый счет в Компании, на котором вы не размещали
                    сделки и/или не вносили депозит в течение периода, превышающего 60 (шестьдесят) календарных дней,
                    будет классифицироваться Компанией как Неактивный счет.
                    Вы также признаете и подтверждаете, что такие Неактивные счета будут облагаться 50 (пятьдесят)
                    комиссионными за обработку, точный размер которых будет зависеть от
                    номинала валюты Клиентского счета. Вы также соглашаетесь, что любые неактивные счета, имеющие
                    нулевой баланс/капитал, будут считаться неактивными счетами. Для возобновления работы Неактивного
                    и/или Дремлющего счета вы должны связаться с Компанией. Неактивный и/или "спящий" счет будет
                    восстановлен при условии предоставления Компании, если потребуется, обновленной идентификационной
                    документации клиента.
                </p>

                <h2>19. ЗАТРАТЫ И РАСХОДЫ</h2>

                <p>19.1. Вы должны оплачивать наши затраты, согласованные с вами время от времени, любые комиссии или
                    другие выплаты, установленные клиринговой организацией, и проценты на любую сумму, причитающуюся
                    нам, по ставкам, установленным нами на тот момент. О любых изменениях в тарифах вы будете уведомлены
                    до момента изменения.</p><p>19.2. Точный размер комиссии будет зависеть от деноминации валюты
                Клиентского счета. Соответствующие комиссии и сборы являются таковыми;</p><p>19.3. "Клиринг прибыли":
                Сборы за торговлю на платформе Компании облагаются комиссией за клиринг, которая варьируется в
                зависимости от доходности инвестиций; в диапазоне от минимальной 1,50 до максимальной 5,00 за сделку.
                Полный список сборов за клиринг представлен на Веб-сайте.</p><p>19.4. Плата за обслуживание: Компания
                взимает за управление и ведение Счета Клиента ежемесячную плату за обслуживание в размере 20. Эта плата
                будет списываться в первый день месяца. В случае, если клиент начинает торговать после 1-го числа
                месяца, оставшаяся плата будет взиматься пропорционально количеству дней, оставшихся в месяце.</p>
                <p>19.5. В случае любого налога на добавленную стоимость или любых других налоговых обязательств,
                    возникающих в связи со сделкой, совершенной от вашего имени или любым другим действием, совершенным
                    по данному соглашению для вас, эта сумма полностью
                    оплачивается вами, вы должны заплатить Компании немедленно по требованию, и Компания имеет полное
                    право дебетовать ваш счет на непогашенную сумму, подлежащую погашению (за исключением налогов,
                    подлежащих уплате Компанией в отношении доходов или прибыли Компании).
                </p>
                <p>19.6. Сбор за неполную заявку</p>
                <ul>
                    <li>19.6.1. В случае, если проверка личности клиента не была завершена, через 30 (тридцать) дней с
                        даты первого депозита взимается комиссия в размере 100. Эта плата будет списываться каждые 30
                        (тридцать) дней со счета клиента.
                    </li>
                </ul>
                <p>19.7. Стимулы</p>
                <ul>
                    <li>
                        19.7.1. Компания, помимо комиссий и платежей, выплачиваемых или предоставляемых Клиенту или
                        другому лицу от имени Клиента, может выплачивать и/или получать вознаграждения/комиссию от
                        третьих лиц, при условии, что эти вознаграждения направлены на повышение качества предлагаемых
                        Клиенту услуг и не нарушают соблюдение обязанности Компании действовать в наилучших интересах
                        Клиента. Компания может выплачивать вознаграждение/комиссию аффилированным лицам, представляющим
                        брокерам, реферальным агентам или другим третьим лицам по письменному соглашению. Эта
                        плата/комиссия связана с фиксированной суммой за привлеченного клиента. Компания имеет
                        обязательства и обязуется раскрывать клиенту по его запросу более подробную информацию о размере
                        вознаграждения/комиссии или любого другого вознаграждения, выплачиваемого Компанией
                        Аффилированным лицам, Представляющим брокерам, рекомендательным агентам или другим третьим
                        лицам. Компания также может получать сборы/комиссию, а также иное вознаграждение от третьих лиц
                        на основании письменного соглашения. Компания получает плату/комиссию от контрагента, через
                        которого она осуществляет сделки. Комиссионные зависят от частоты/объема сделок, совершаемых
                        через контрагента. Компания обязана и обязуется раскрыть клиенту, по его запросу, более
                        подробную информацию о размере
                        сборов/комиссии или любого другого вознаграждения, полученного Компанией от третьих лиц.
                    </li>
                </ul>

                <h2>20. ОТВЕТСТВЕННОСТЬ КОМПАНИИ И ВОЗМЕЩЕНИЕ УБЫТКОВ</h2>

                <p>20.1. Следует отметить, что Компания и любая связанная с Компанией организация будут осуществлять
                    сделки добросовестно и с должной осмотрительностью, но не несут

                    ответственности за любое упущение, преднамеренное упущение или мошенничество со стороны любого лица,
                    фирмы или компании, от которых Компания получает инструкции по выполнению Распоряжений и/или от
                    которых осуществляются сделки от вашего имени, включая случаи, когда это будет результатом
                    халатности, преднамеренного упущения или мошенничества со стороны Компании.
                </p><p>20.2. Компания не несет ответственности за любые упущенные вами возможности, которые привели либо
                к убыткам, либо к снижению (или повышению) стоимости ваших Финансовых инструментов.</p><p>20.3. В случае
                возникновения у Компании каких-либо претензий, убытков, ущерба, ответственности или расходов,
                возникающих в ходе предоставления Услуг и всех связанных с ними операций, выполняемых в качестве
                средства для предоставления Вам этих Услуг, как это оговорено в настоящем Соглашении, или в связи с
                возможным отчуждением Ваших Финансовых инструментов, Вы несете полную ответственность за эти
                убытки/расходы/обязательства/требования, в то время как Компания не несет абсолютно никакой
                ответственности, и поэтому Вы обязаны возместить Компании вышеуказанные убытки.
            </p><p>20.4. Компания не несет ответственности за любой ущерб, причиненный вам в результате какого-либо
                упущения, халатности, преднамеренного бездействия или мошенничества со стороны банка, в котором ведется
                банковский счет Компании.</p>
                <p>20.5. Компания не несет ответственности за потерю финансовых инструментов и денежных средств в
                    случаях, когда ваши активы хранятся у третьей стороны, например, в банке, или за действие, которое
                    было совершено на основании недостоверной информации, имеющейся в ее распоряжении до того, как вы
                    были проинформированы об изменении указанной информации.</p><p>20.6. Компания прилагает все усилия
                для того, чтобы банки и учреждения, в которые вкладываются ваши средства и/или Финансовые инструменты,
                имели хорошее положение и репутацию. Однако Компания не несет ответственности в случае убытков,
                возникших в результате ухудшения финансового положения банка или учреждения, или в случае таких событий,
                как ликвидация, управление или любое другое событие, которое приводит к банкротству банка или учреждения
                и, следовательно, к потере всех или части депонированных средств.</p>
                <p>20.7. Без ущерба для любых
                    других условий настоящего Соглашения, Компания не будет нести ответственность за:</p>
                <ul>
                    <li>20.7.1. Системные ошибки (компании или поставщиков услуг)</li>
                    <li>20.7.2. Задержки</li>
                    <li>20.7.3. Вирусы
                    </li>
                    <li>20.7.4. Несанкционированное использование</li>
                    <li>20.7.5. За любое действие, совершенное рынком, расчетной палатой или регулирующим органом или по
                        их указанию.
                    </li>
                </ul>
                <p>20.8. Компания
                    не несет ответственности перед вами за частичное или неполное выполнение своих обязательств по
                    настоящему договору по любой причине вне разумного контроля Компании, включая, без ограничений,
                    любые
                    поломки, задержки, сбои или отказы средств передачи, связи или компьютеров, забастовки,
                    террористические
                    акты, стихийное бедствие,

                    акты и постановления любых правительственных или надправительственных органов или властей или
                    неспособность соответствующего промежуточного брокера или агента, агента или принципала кастодиана,
                    субкастодиана, дилера, Маркета, клиринговой палаты или регулирующей или саморегулируемой организации
                    Компании по любой причине выполнить свои обязательства.
                </p><p>20.9. Ни Компания, ни ее директора, должностные лица, сотрудники или агенты не несут
                ответственности
                за любые потери, убытки, затраты или расходы, возникшие в результате халатности, нарушения договора,
                введения в заблуждение или иным образом, понесенные вами в рамках настоящего Соглашения (включая любую
                Сделку или в случаях, когда Компания отказалась от заключения предлагаемой Сделки). Ни при каких
                обстоятельствах Компания не несет ответственности за убытки, понесенные вами или любой третьей стороной
                за любой специальный или косвенный ущерб, упущенную выгоду, потерю деловой репутации или потерю деловых
                возможностей, возникающие в рамках или в связи с настоящим Соглашением, будь то в результате халатности,
                нарушения договора, введения в заблуждение или иным образом.</p>
                <p>20.10. Вы должны выплатить Компании такие суммы, которые она может время от времени требовать в
                    качестве или для удовлетворения любого дебетового остатка на любом из ваших счетов в Компании и, на
                    основе полного возмещения, любых убытков, обязательств, затрат или расходов (включая судебные
                    издержки), налогов, Компания может понести или подвергнуться налогам и сборам в отношении любого из
                    ваших счетов или любой сделки или любой соответствующей сделки на рынке или с промежуточным брокером
                    или в результате любого введения вас в заблуждение или любого нарушения вами своих обязательств по
                    настоящему Соглашению (включая любую сделку) или в результате принудительного осуществления прав
                    Компании.</p>
                <p>20.11. Вы признаете, что не полагались и не были побуждены к заключению настоящего Соглашения
                    никакими заявлениями, кроме тех, которые прямо изложены в настоящем Соглашении. Компания не будет
                    нести ответственность перед вами за представление, которое не указано в настоящем Соглашении и не
                    является мошенническим.
                </p>

                <h2>21. СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ И ВНЕСЕНИЕ В НЕГО ИЗМЕНЕНИЙ</h2>

                <p>21.1. Настоящее Соглашение действует в течение неопределенного периода времени до его расторжения
                    либо Компанией, либо вами, либо обоими.</p>

                <p>21.2. Соглашение может быть изменено в следующих случаях:</p>
                <ul>
                    <li>21.2.1. В одностороннем порядке Компанией, если такая поправка необходима после изменения
                        законодательства или если IFSC или любой другой регулирующий орган издает решения или
                        обязательные директивы, которые влияют на Соглашение. В любом таком случае Компания должна
                        уведомить Клиента о таком изменении либо в письменном виде, либо по электронной почте, либо
                        через свою главную веб-страницу, и ваше согласие не требуется для любого такого изменения.
                    </li>
                    <li>21.2.2. В случаях, когда внесение изменений в Соглашение не требуется в связи с изменением
                        законодательной базы, Компания уведомляет вас о соответствующих изменениях через свою главную
                        веб-страницу и/или по электронной почте. При возникновении возражений вы можете расторгнуть
                        Соглашение в течение 7 (семи) дней с момента уведомления путем отправки заказного письма и при
                        условии, что все незавершенные сделки от вашего имени будут завершены. По истечении
                        вышеуказанного срока без возражений со стороны Клиента считается, что Вы согласны и/или
                        принимаете содержание поправки.
                    </li>
                </ul>

                <h2>22. НЕНАДЛЕЖАЩАЯ ИЛИ НЕПРАВОМЕРНАЯ
                    ТОРГОВЛЯ И/ИЛИ НЕСАНКЦИОНИРОВАННАЯ ДЕЯТЕЛЬНОСТЬ
                </h2>

                <p>22.1. Целью Компании является предоставление наиболее эффективной торговой ликвидности в виде
                    потоковых, торгуемых цен для большинства финансовых инструментов, которые мы предлагаем на торговой
                    платформе. В результате высокоавтоматизированного характера предоставления этих потоковых, доступных
                    для торговли цен, клиенты признают и принимают, что время от времени могут возникать ценовые
                    ошибки.</p>
                <p>22.2. Если Клиенты осуществляют торговые стратегии с целью использования таких
                    неправильных котировок или действуют недобросовестно (широко известный как "снайпинг"), Компания
                    будет
                    рассматривать это как неприемлемое поведение. Если Компания по своему усмотрению и добросовестно
                    определит, что любой из Клиентов и/или любой его/ее представитель, торгующий от его/ее имени,
                    использует
                    преимущества, получает выгоду, пытается получить преимущества или выгоду от таких неправильных
                    котировок
                    или что любой Клиент совершает любые другие ненадлежащие или злоупотребляющие торговые действия,
                    такие
                    как, например:</p>
                <ul>
                    <li>22.2.1. ордера, размещенные на основе манипулируемых цен в результате системных ошибок или сбоев
                        в работе системы;
                    </li>
                    <li>22.2.2. арбитражная торговля по ценам, предлагаемым нашими платформами, в результате системных
                        ошибок;
                    </li>
                    <li>22.2.3. мошенничество/незаконные действия, которые привели к сделке;</li>
                    <li>22.2.4. скоординированные сделки связанных сторон с целью воспользоваться ошибками в системах и
                        задержками в обновлении систем.
                    </li>
                </ul>
                <p>22.3. Клиенты соглашаются и признают, что они не будут использовать наши продукты и
                    услуги для любой Несанкционированной деятельности. "Несанкционированная деятельность" означает любое
                    действие, включая, но не ограничиваясь отмыванием денег, арбитражем, торговлей по внерыночным
                    котировкам
                    или любой другой деятельностью, связанной с покупкой Финансовых продуктов на одном рынке для
                    немедленной
                    перепродажи на другом рынке с целью получения прибыли от расхождения цен или ценовой ошибки.</p>
                <p>22.4.
                    В таких случаях и когда мы обоснованно полагаем, что любая сделка связана с несанкционированной
                    и/или
                    неправомерной деятельностью, Компания имеет и сохраняет за собой право на:</p>
                <ul>
                    <li>22.4.1. корректировать предлагаемые выплаты, доступные для таких Клиентов;</li>
                    <li>22.4.2. ограничивать доступ клиентов к потоковым, мгновенно торгуемым котировкам, включая
                        предоставление только ручных котировок;
                    </li>
                    <li>22.4.3. получать со счетов Клиентов любую историческую торговую прибыль, которую они получили в
                        результате такого злоупотребления ликвидностью, как определено Компанией в любое время в течение
                        наших торговых отношений;
                    </li>
                    <li>22.4.4. отклонить ордер или отменить сделку.;
                    </li>
                    <li>22.4.5. отменить или зарезервировать любую операцию;</li>
                    <li>22.4.6. немедленно прекратить наши торговые отношения.</li>
                </ul>
                <p>22.5. Компания разработала и будет продолжать разрабатывать любые инструменты, необходимые для
                    выявления мошеннического и/или незаконного доступа и использования нашей системы
                    онлайн-торговли.</p><p>22.6. Концепция использования торговых стратегий, направленных на
                использование ошибок в ценах и/или заключение сделок по внерыночным ценам и/или с использованием
                преимуществ задержек в Интернете, широко известных как "арбитраж", "снайпинг" или "скальпинг" (далее
                совместно именуемые "Арбитраж"), не может существовать на внебиржевом рынке, где Клиент покупает или
                продает непосредственно у Принципала.</p><p>22.7. Компания оставляет за собой право не допускать
                злоупотребления Арбитражем на своей системе онлайн-торговли и/или в связи со своими услугами.</p>
                <p>22.8. Компания не обязана связываться с вами, чтобы посоветовать соответствующие действия в свете
                    изменений в рыночных условиях или иным образом. Клиенты соглашаются и признают, что торговля
                    внебиржевыми рыночными контрактами на разницу - CFD -
                    является высоко спекулятивной и волатильной, и что после исполнения любой сделки клиенты несут
                    полную ответственность за установление и поддержание контакта с нами, а также за мониторинг открытых
                    позиций и обеспечение своевременного предоставления любых дальнейших инструкций.
                </p>

                <h2>23. УСЛОВИЯ ТОРГОВЛИ CFD.</h2>

                <p>23.1. Ежедневная плата за финансирование может применяться к каждой открытой позиции Forex, CFDs на
                    момент закрытия торгового дня Компании в отношении данного Forex, CFD. Если такая плата за
                    финансирование применима, она либо будет запрошена Клиентом непосредственно у Компании, либо будет
                    выплачена Компанией Клиенту, в зависимости от типа FX/CFD и характера позиции, которую занимает
                    Клиент. Метод расчета платы за финансирование варьируется в зависимости от типа FX/CFD, к которому
                    она применяется. Плата за финансирование будет зачислена или списана (в зависимости от ситуации) со
                    счета Клиента на следующий торговый день после дня, к которому она относится.</p><p>23.2. Компания
                оставляет за собой право изменять метод расчета платы за финансирование, ставки финансирования и/или
                типы FX/CFD, к которым применяется плата за финансирование. По некоторым видам FX/CFD Клиент должен
                уплачивать комиссию за открытие и закрытие позиций FX/CFD. Такая комиссия списывается со счета Клиента
                одновременно с открытием или закрытием Компанией соответствующего FX/CFD. Изменения в наших процентных
                ставках по свопам и расчетах производятся по собственному усмотрению и без предварительного уведомления.
                Клиентам необходимо всегда проверять информацию о текущих ставках. Информация о свопах по каждому
                инструменту отображается на Торговой платформе. Ставки могут быстро меняться из-за рыночных условий
                (изменения процентных ставок, волатильности, ликвидности и т.д.) и из-за различных вопросов, связанных с
                риском, которые решаются исключительно по усмотрению фирмы.</p><p>23.3. Любая открытая сделка FX/CFD,
                проведенная Клиентом в конце торгового дня по решению Компании или в выходные дни, автоматически
                переносится на следующий рабочий день, чтобы избежать автоматического закрытия и физического расчета по
                сделке. Клиент признает, что при переносе таких сделок на следующий рабочий день, комиссия может быть
                либо добавлена, либо вычтена со счета Клиента в отношении такой сделки. Информация о ставках свопа для
                каждого Инструмента отображается в ссылке "подробности" для каждого конкретного Инструмента на Торговой
                платформе</p>
                <p>23.4. В среду, около 00:00 GMT, плата за ночной ролловер умножается на три (x3), чтобы компенсировать
                    предстоящие выходные.</p>
                <p>23.5. Операции с истечением срока действия и пролонгация</p>
                <ul>
                    <li>23.5.1. Торговля CFD, привязанными к рыночной цене определенного базового актива, включая
                        рыночную цену фьючерсных контрактов. За несколько дней до истечения срока действия базового
                        актива, к которому привязан CFD, базовый актив заменяется другим активом, и котировка CFD
                        изменяется соответствующим образом.
                    </li>
                    <li>23.5.2. Для определенных Инструментов на нашей платформе, которые основаны на фьючерсных
                        контрактах, мы можем, исключительно и абсолютно по своему усмотрению, установить дату и время
                        истечения срока действия для конкретного Инструмента. Информация о дате истечения срока действия
                        для каждого Инструмента отображается на Торговой платформе.
                    </li>
                    <li>23.5.3. В случае, если мы установим дату истечения срока действия для конкретного инструмента,
                        она будет отображаться на Торговой платформе в разделе подробной информации по каждому
                        конкретному инструменту. Вы признаете, что на вас лежит ответственность за то, чтобы быть в
                        курсе даты и времени истечения срока действия.
                    </li>
                    <li>23.5.4. Если вы не закроете открытую сделку в отношении Инструмента, имеющего дату истечения, до
                        такой даты истечения, сделка автоматически закроется в дату истечения. Сделка закрывается по
                        цене, которая будет последней ценой, котируемой на Торговой платформе непосредственно перед
                        применимой Датой истечения срока и временем.
                    </li>
                </ul>
                <p>23.6. Исламские счета</p>
                <ul>
                    <li>23.6.1. В случае если Клиент в силу соблюдения исламских религиозных убеждений не может получать
                        или выплачивать проценты, Клиент может выбрать способ, предусмотренный Компанией, обозначить
                        свой торговый счет как исламский счет, на который не начисляются проценты овернайт и/или
                        ролловеры, и на который он не имеет права.
                    </li>
                    <li>23.6.2. Настоящим клиент подтверждает и/или принимает и/или заявляет, что запрос на перевод его
                        счета в разряд исламских будет сделан только по причине указанных исламских религиозных
                        убеждений и ни по какой другой причине. Компания оставляет за собой право отказать в принятии
                        запроса клиента о присвоении его счету статуса исламского счета по своему единоличному и
                        абсолютному усмотрению, которое будет окончательным и неоспоримым для клиента.
                    </li>
                    <li>23.6.3. В случае, если Клиент обозначает свой счет как исламский счет, Клиент не может держать
                        операции по такому счету открытыми более 14 дней и не может иным образом злоупотреблять таким
                        преимуществом.
                    </li>
                    <li>23.6.4. Компания может начислять комиссию на каждую сделку, совершенную на Исламском счете;
                        и/или оставляет за собой право отменить вышеуказанную льготу в любое время и предпринять любые
                        действия, необходимые по усмотрению Компании в связи со злоупотреблением данной льготой. Такие
                        действия могут включать, без ограничений, обозначение исламского счета как обычного счета и
                        ретроактивное осуществление необходимых корректировок (например, списание со счета сумм, равных
                        суммам, выплаченным Компанией в качестве процентов), ограничение и/или запрет клиенту
                        хеджировать свои позиции, отмену сделок и корректировку остатков на счете.
                    </li>
                </ul>
                <p>23.7. Типы ордеров:</p>
                <ul>
                    <li>23.7.1. Ордера могут быть размещены как рыночные ордера для покупки или продажи как можно скорее
                        по цене, доступной на рынке, или по отдельным продуктам как лимитные стоп-ордера для торговли,
                        когда цена достигнет заранее определенного уровня.
                        Лимитные ордера на покупку и стоп-приказы на продажу должны быть размещены ниже текущей рыночной
                        цены, а лимитные ордера на продажу и стоп-приказы на покупку должны быть размещены выше текущей
                        рыночной цены. При достижении цены спроса для ордеров на продажу или цены предложения для
                        ордеров на покупку ордер будет исполнен в кратчайшие сроки по цене, доступной на рынке. Лимиты и
                        стоп-приказы исполняются в соответствии с "Политикой Компании по исполнению ордеров" и не
                        гарантируют исполнения по указанной цене или сумме, если это прямо не указано Компанией для
                        конкретного ордера.
                    </li>
                </ul>
                <p>23.8. Проскальзывание
                    Вы предупреждены, что при торговле финансовыми инструментами может возникнуть проскальзывание. Это
                    ситуация, когда в момент подачи ордера на исполнение конкретная цена, указанная клиенту, может быть
                    недоступна; поэтому ордер будет исполнен близко к запрашиваемой клиентом цене или на несколько
                    пунктов от нее. Таким образом, проскальзывание - это разница между ожидаемой ценой ордера и ценой,
                    по которой ордер фактически исполнен. Если цена исполнения лучше, чем цена, запрошенная клиентом,
                    это называется положительным проскальзыванием. Если цена исполнения хуже, чем цена, запрошенная
                    клиентом, это называется отрицательным проскальзыванием. Пожалуйста, имейте в виду, что
                    проскальзывание является нормальным элементом при торговле финансовыми инструментами.
                    Проскальзывание чаще всего возникает в периоды неликвидности или повышенной волатильности (например,
                    в связи с анонсами новостей, экономическими событиями и открытием рынка и другими факторами), делая
                    ордер по определенной цене невыполнимым. Ваши ордера могут быть не выполнены по заявленным ценам.
                    Проскальзывание может возникать на всех типах счетов, которые мы предлагаем.
                    Следует отметить, что проскальзывание может возникать и при исполнении стоп-лосс ордеров, лимитных
                    ордеров и других типов ордеров. Мы не гарантируем исполнение ваших отложенных ордеров по указанной
                    цене. Однако мы подтверждаем, что ваш ордер будет исполнен по следующей наилучшей доступной рыночной
                    цене от цены, указанной вами в отложенном ордере.
                </p><p>23.9. Клиент настоящим признает и соглашается с
                тем, что Компания может по своему усмотрению добавлять, удалять или приостанавливать работу Платформы с
                любым Финансовым инструментом, с любым типом Базового актива или рынка, время от времени в случае
                события трансформации акций (например, в результате поглощения,
                консолидации/разделения акций, слияния, выделения, национализации, исключения из списка и т.д.) или если
                в конкретном Финансовом инструменте на тот момент нет позиций Клиента. Кроме того, в случае, если мы
                больше не можем продолжать предоставлять инструмент в его существующем формате, мы оставляем за собой
                право по собственному усмотрению изменить содержание или условия инструмента, включая дату истечения
                срока действия, часы торговли или любые другие параметры на вкладке "Детали инструмента", уведомив вас
                об этом.
            </p><p>23.10. Для того чтобы сохранить определенный статус счета, вы должны ежемесячно накапливать
                минимальную сумму баллов трейдера, необходимую для данного статуса счета. Вы можете просто вернуться на
                любой статус счета, выполнив ежемесячное требование по количеству очков трейдера для данного уровня
                счета при условии, что вы соответствуете критериям минимального депозита, или сделав минимально
                необходимые депозиты, которые автоматически повысят вас до следующего уровня счета. Ежемесячные
                требования к количеству очков трейдера и накопленные очки трейдера можно посмотреть в меню вашего
                счета</p>
                <p>23.11. Льготы - Поглощения и преобразования (включая такие события, как консолидация/разделение
                    акций, слияние, поглощение, выделение, MBO, исключение из
                    списка и т.д.). В зависимости от обстоятельств каждого события, наша политика заключается в закрытии
                    любых открытых позиций клиента по рыночной цене непосредственно перед наступлением события. В
                    результате такого события, если какой-либо Инструмент становится предметом корректировки в
                    результате действий по поглощению или преобразованию, мы должны определить соответствующую
                    корректировку, которая должна быть внесена в цену контракта или количество контракта, как мы считаем
                    нужным, чтобы учесть разбавляющий или концентрирующий эффект действий. Такая корректировка должна
                    представлять собой экономический эквивалент прав и обязательств нас и вас непосредственно перед
                    действием.
                </p>

                <h2>24. РАСТОРЖЕНИЕ</h2>

                <p>24.1. Вы имеете право расторгнуть Соглашение, направив Компании письменное уведомление не менее чем
                    за 7 (семь) дней, указав в нем дату расторжения, при условии, что в случае такого расторжения все
                    открытые позиции Клиента будут закрыты к дате расторжения без отступления от всех вышеуказанных
                    положений, включая сборы, платежи и штрафы.</p><p>24.2. Компания может расторгнуть Соглашение,
                направив вам письменное уведомление за 7 (семь) дней с указанием даты расторжения.</p>
                <p>24.3. Компания может расторгнуть Соглашение немедленно без предварительного уведомления в следующих
                    случаях:</p>
                <ul>
                    <li>24.3.1. Смерть клиента;</li>
                    <li>24.3.2. В случае, если решение о банкротстве или ликвидации вас принимается через собрание или
                        через подачу заявления о вышеуказанном;
                    </li>
                    <li>24.3.3. Прекращение деятельности требуется любым компетентным регулирующим органом или
                        органом;
                    </li>
                    <li>24.3.4. Вы нарушаете какое-либо положение Соглашения и, по мнению Компании, Соглашение не может
                        быть выполнено;
                    </li>
                    <li>24.3.5. Вы нарушаете любой закон или постановление, которым вы подчиняетесь, включая, но не
                        ограничиваясь этим, законы и постановления, относящиеся к валютному контролю и требованиям
                        регистрации;
                    </li>
                    <li>24.3.6. Вы прямо или косвенно вовлекаете компанию в любой вид мошенничества.</li>
                    <li>24.3.7. Наступает событие дефолта, определенное в разделе 24 настоящего Соглашения.
                    </li>
                </ul>

                <p>24.4. Расторжение Соглашения ни в коем случае не затрагивает возникшие права, существующие
                    обязательства или любые положения договора, которые должны были оставаться в силе после расторжения,
                    и в случае расторжения Соглашения вы должны выплатить:</p>
                <ul>
                    <li>24.4.1. Любой неуплаченный гонорар Компании и любая другая сумма, подлежащая уплате Компании;
                    </li>
                    <li>24.4.2. Любая плата и дополнительные расходы, понесенные или подлежащие к компенсации Компанией
                        в результате расторжения Соглашения;
                    </li>
                    <li>24.4.3. Любые убытки, возникшие в ходе организации или урегулирования неисполненных
                        обязательств.
                    </li>
                </ul>

                <p>24.5. В случае нарушения вами данного соглашения Компания оставляет за собой право отменить все
                    предыдущие сделки, которые подвергают риску интересы Компании и/или всех или любых ее клиентов,
                    прежде чем расторгнуть Соглашение.</p>

                <h2>25. СОБЫТИЯ ДЕФОЛТА И ПРАВА ПРИ ДЕФОЛТЕ</h2>

                <p>25.1. Следующие события являются "Событиями неисполнения финансовых обязательств", при наступлении
                    которых Компания имеет право осуществлять свои права в соответствии с пунктом ниже:</p>
                <ul>
                    <li>25.1.1. Несоблюдение или невыполнение вами любого другого положения настоящего Соглашения, и
                        такое несоблюдение продолжается в течение одного рабочего дня после того, как Компания направила
                        вам уведомление о неисполнении.
                    </li>
                    <li>25.1.2. Начало третьим лицом процедур, направленных на ваше банкротство (в случае физического
                        лица) или ваше банкротство или другое подобное добровольное дело о ликвидации (в случае
                        юридического лица) в соответствии с действующим законодательством или любые другие подобные
                        процедуры, аналогичные вышеупомянутым в отношении вас.
                    </li>
                    <li>25.1.3. Вы пользуетесь задержкой цен и выставляете ордера по устаревшим ценам, торгуете по
                        внерыночным ценам и/или в нерабочее время и совершаете любые другие действия, представляющие
                        собой ненадлежащую торговлю.
                    </li>
                    <li>25.1.4. Вы умерли или стали недееспособным (если физическое лицо).</li>
                    <li>25.1.5. Любое заявление или гарантия, сделанное или данное, или считающееся сделанным или данным
                        вами по настоящему Соглашению, оказывается ложным или вводящим в заблуждение в любом
                        существенном отношении на момент, когда оно было сделано, или дано, или считалось сделанным или
                        данным.
                    </li>
                    <li>25.1.6. Любая другая ситуация, когда Компания обоснованно считает это необходимым или
                        желательным для своей собственной защиты, или предпринимается какое-либо действие, или
                        происходит событие, которое, по мнению Компании, может оказать существенное негативное влияние
                        на вашу способность выполнять какие-либо из своих обязательств по настоящему Соглашению.
                    </li>
                </ul>
                <p>25.2. При наступлении случая неисполнения обязательств, Компания имеет право предпринять по своему
                    усмотрению любое из следующих действий в любое время и без предварительного уведомления вас:</p>
                <ul>
                    <li>25.2.1. вместо возврата вам инвестиций, эквивалентных тем, которые были зачислены на ваш счет,
                        выплатить вам справедливую рыночную стоимость таких инвестиций на момент реализации Компанией
                        такого права,
                    </li>
                    <li>25.2.2. продать такие ваши инвестиции, которые находятся во владении Компании или во владении
                        любого номинального лица, или третьей стороны, назначенной в соответствии с настоящим
                        Соглашением, в каждом случае, как Компания может по своему абсолютному усмотрению выбрать и/или
                        на таких условиях, как Компания может по своему абсолютному усмотрению посчитать нужным (не неся
                        ответственности за любые

                        потери или снижение цены), чтобы получить средства, достаточные для покрытия любой суммы,
                        причитающейся вам по настоящему Соглашению,
                    </li>
                    <li>25.2.3. закрыть, заменить или обратить вспять любую сделку, купить, продать, занять или
                        одолжить, или заключить любую другую сделку, или предпринять, или воздержаться от принятия таких
                        других действий в такое время, или время и таким образом, как, по собственному усмотрению
                        Компании, Компания считает необходимым или уместным для покрытия, уменьшения или устранения
                        своих убытков или ответственности по отношению к любому из ваших контрактов, позиций или
                        обязательств,
                    </li>
                    <li>25.2.4. рассматривать любую или все незавершенные на тот момент сделки, как отказ Клиента от
                        исполнения обязательств, в этом случае обязательства Компании по такой сделке или сделкам
                        аннулируются и прекращаются.
                    </li>
                </ul>

                <h2>26. ПОНИМАНИЕ РИСКОВ</h2>

                <p>26.1. Следует отметить, что в связи с рыночными условиями и колебаниями стоимость Финансовых
                    инструментов может увеличиться или уменьшиться, или даже свестись к нулю. Независимо от информации,
                    которую Компания может предоставить вам, вы соглашаетесь и признаете возможность наступления таких
                    случаев.</p><p>26.2. Вы осознаете и признаете, что существует большой риск понести потери и убытки в
                результате инвестиционной деятельности (покупка и/или продажа Финансовых инструментов) через Компанию и
                Торговую платформу Компании, и соглашаетесь с тем, что Вы готовы принять на себя этот риск при
                вступлении в данные деловые отношения.</p>
                <p>26.3. Вы заявляете, что прочитали, поняли и безоговорочно
                    принимаете следующее:</p>
                <ul>
                    <li>26.3.1. Информация о предыдущих показателях финансового инструмента не гарантирует его текущих
                        и/или будущих показателей. Исторические данные не являются и не должны рассматриваться, как
                        отражающие будущую доходность любого финансового инструмента.
                    </li>
                    <li>26.3.2. В случаях, когда финансовые инструменты торгуются в валюте, отличной от валюты вашей
                        страны проживания, вы подвергаетесь риску изменения обменного курса, что приведет к снижению
                        стоимости и цены финансовых инструментов и, соответственно, их эффективности.
                    </li>
                    <li>26.3.3. Вы должны осознавать, что рискуете потерять все вложенные средства, и должны приобретать
                        Финансовые инструменты только в том случае, если вы готовы сделать это, если это произойдет.
                        Кроме того, все понесенные расходы и комиссионные будут оплачиваться с вашей стороны.
                    </li>
                </ul>
                <p>26.4. Максимальный убыток, который может понести любой клиент, - это сумма
                    денег, уплаченная им Компании, включая комиссионные за заключение сделок дневной торговли.</p>
                <p>26.5. Каждый финансовый контракт, приобретенный клиентом через сайт Компании,
                    является индивидуальным Соглашением, заключенным между клиентом и Компанией, и не подлежит передаче,
                    обсуждению или переуступке третьим лицам.
                </p>

                <h2>27. КОНФИДЕНЦИАЛЬНАЯ ИНФОРМАЦИЯ</h2>

                <p>27.1. Компания не обязана раскрывать вам какую-либо информацию или принимать во внимание какую-либо
                    информацию при принятии какого-либо решения или при совершении каких-либо действий от вашего имени,
                    если иное не оговорено и не указано в настоящем Соглашении и если это не предусмотрено
                    соответствующими действующими законами и правилами и директивами.</p><p>27.2. Компания никогда не
                будет раскрывать частную или иную конфиденциальную информацию в отношении наших клиентов и бывших
                клиентов третьим лицам без письменного согласия наших клиентов, за исключением таких конкретных случаев,
                когда раскрытие информации является требованием закона или иным образом необходимо для проведения
                анализа личности клиента в целях защиты его счета и обеспечения сохранности его личной информации.</p>
                <p>27.3. Компания будет обрабатывать все ваши персональные данные, согласно соответствующим законам
                    и правилам защиты персональных данных.</p>
                <p>27.4. При начале сотрудничества родственников клиента с организацией, клиент обязуется
                    проинформировать об этом организацию в течении трех суток с момента сотрудничества родственника
                    для оформления финансовой декларации.</p>

                <h2>28. ПРИМЕЧАНИЯ</h2>

                <p>28.1. Если иное не предусмотрено специально, любые уведомления, инструкции, разрешения, запросы или
                    другие сообщения, которые должны быть переданы вами Компании в рамках Соглашения, должны быть
                    оформлены в письменном виде и направлены по почтовому адресу Компании, указанному на сайте Компании,
                    или по любому другому адресу, который Компания может время от времени указывать вам для этой цели, и
                    вступают в силу только после фактического получения Компанией, при условии, что они не нарушают и не
                    противоречат какому-либо условию настоящего Соглашения.</p><p>28.2. Компания оставляет за собой
                право указать любой другой способ связи с вами.</p>

                <h2>29. ЗАПИСЬ ТЕЛЕФОННЫХ РАЗГОВОРОВ</h2>

                <p>29.1. Вы признаете, что Компания может записывать телефонные разговоры между вами и Компанией без
                    использования предупреждающего сигнала, чтобы обеспечить своевременную и точную запись существенных
                    условий Сделки и любой другой существенной информации, относящейся к Сделке. Такие записи будут
                    являться исключительной собственностью Компании и приниматься вами в качестве доказательства
                    отданных Распоряжений или инструкций.</p>

                <h2>30. КОНФЛИКТ ИНТЕРЕСОВ</h2>

                <p>30.1. В соответствии с Применимыми нормативными актами Компания обязана иметь договоренности по
                    управлению конфликтами интересов между Компанией и ее клиентами и между другими клиентами. Компания
                    приложит все разумные усилия, чтобы избежать конфликтов интересов, если их невозможно избежать,
                    Компания должна обеспечить справедливое и честное отношение к вам и защиту ваших интересов в любое
                    время.</p>

                <p>30.2. Вы подтверждаете и соглашаетесь с тем, что ознакомились и приняли "Политику в отношении
                    конфликтов интересов", которая была предоставлена вам в процессе регистрации и размещена на
                    официальном сайте Компании.</p>

                <h2>31. ОБЩИЕ ПОЛОЖЕНИЯ</h2>

                <p>31.1. Вы признаете, что Компания или от ее имени не делала вам никаких заявлений, которые каким-либо
                    образом побудили или убедили вас заключить Соглашение.</p><p>31.2. В случае, если какое-либо
                положение Соглашения является или становится в любое время незаконным, недействительным или неисполнимым
                в любом отношении в соответствии с законом и/или постановлением любой юрисдикции, законность,
                действительность или исполнимость остальных положений Соглашения или законность, действительность или
                исполнимость этого положения в соответствии с законом и/или постановлением любой другой юрисдикции не
                затрагивается.</p><p>31.3. Все сделки от вашего имени подчиняются законам, которые регулируют создание и
                деятельность, положениям, договоренностям, директивам, циркулярам и обычаям (далее совместно именуемым
                "Законы и положения") IFSC и любых других органов, которые регулируют деятельность Инвестиционных
                компаний (как определено в таких Законах и положениях), в том виде, в котором они время от времени
                изменяются или дополняются. Компания имеет право принимать или не принимать любые меры, которые она
                считает желательными для соблюдения действующих на данный момент Законов и Положений. Любые такие меры,
                которые могут быть приняты, и все действующие Законы и Положения будут обязательными для вас.</p>
                <p>31.4. Вы должны принять все разумно необходимые меры (включая, без ущерба для общности
                    вышеизложенного, оформление всех необходимых документов), чтобы Компания могла надлежащим образом
                    выполнить свои обязательства по Соглашению.</p><p>31.5. Расположение подробной информации о
                совершении и условиях инвестиционных сделок с Финансовыми инструментами, проводимых Компанией, и другой
                информации о
                деятельности Компании доступно и адресовано любым физическим и юридическим лицам на сайте Компании в
                сети Интернет.
            </p><p>31.6. Положения настоящего документа, выраженные на английском языке, превалируют над положениями
                любого другого перевода того же документа, на каком бы языке ни был возможный перевод.</p>

                <h2>32. ДЕЙСТВУЮЩЕЕ ЗАКОНОДАТЕЛЬСТВО, ЮРИСДИКЦИЯ</h2>

                <p>32.1. Настоящее Соглашение и все транзакционные отношения между вами и Компанией регулируются
                    законодательством Сент-Люсии, а компетентным судом для разрешения любого спора, который может
                    возникнуть между ними, является окружной суд округа, в котором находится штаб-квартира Компании.</p>
            </div>
        </section>
    );
}

export default TermsAndConditionsScreen;
import React, {useEffect} from 'react';
import FirstSection from "../../Components/FirstSection/FirstSection";
import SecondSection from "../../Components/SecondSection/SecondSection";
import ThirdSection from "../../Components/ThirdSection/ThirdSection";
import FourthSection from "../../Components/FourthSection/FourthSection";

function HomePage({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <div id={'homePage'}>
            <FirstSection/>

            <SecondSection/>

            <ThirdSection/>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            {/*<FourthSection/>*/}
        </div>
    );
}

export default HomePage;
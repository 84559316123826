import React from 'react';
import './goUpButton.sass'

function GoUpButton(props) {

    const goUpHandler = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    return (
        <button id={'goUpButton'} type={'button'} onClick={goUpHandler}>
            <i className={'fa-solid fa-arrow-up'}></i>
        </button>
    );
}

export default GoUpButton;
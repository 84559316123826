import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function DataSecurityScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);

    return (
        <section id={'dataSecurityScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>Общее положение о защите данных</h1>

                <p>Определения</p>

                <h2>ОБЩЕЕ ПОЛОЖЕНИЕ О ЗАЩИТЕ ДАННЫХ GDPR АСТ</h2>

                <p>Контролер данных означает физическое или юридическое лицо, которое (самостоятельно или совместно с
                    другими лицами) определяет цели и способ обработки любой личной информации.</p><p>Обработчик данных
                означает любое физическое или юридическое лицо, которое обрабатывает данные от имени Контроллера
                данных.</p><p>Субъект данных — это любое живое физическое лицо, использующее наш Сервис и являющееся субъектом Персональных данных.</p>

                <h2>ВВЕДЕНИЕ</h2>

                <p>NCC («нас», «мы», «наш») управляет веб-сайтом («Сервис»).</p><p>Эта страница информирует вас о нашей
                политике в отношении сбора, использования и раскрытия персональных данных при использовании вами нашего
                Сервиса, а также о вариантах выбора, связанных с этими данными.</p><p>Мы используем ваши данные для предоставления и улучшения Сервиса. Используя Сервис, вы соглашаетесь на сбор и использование информации в соответствии с настоящей политикой. Если иное не определено в настоящей Политике общего регулирования защиты данных, термины, используемые в настоящей Политике общего регулирования защиты данных, имеют те же значения, что и в наших Условиях.</p>

                <h2>ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

                <p>Наши принципы обработки персональных данных:</p><p>Справедливость и законность. Когда мы обрабатываем
                персональные данные, индивидуальные права Субъектов данных должны быть защищены. Все личные данные
                должны собираться и обрабатываться законным и справедливым образом.</p><p>Ограничено определенной целью.
                Персональные данные Субъекта должны обрабатываться только для определенных целей.</p><p>Прозрачность. Субъект данных должен быть проинформирован о том, как его/ее данные собираются, обрабатываются и используются.</p>

                <h2>СБОР И ИСПОЛЬЗОВАНИЕ ИНФОРМАЦИИ</h2>

                <p>Мы собираем несколько различных типов информации для различных целей, чтобы предоставлять и улучшать
                    наш Сервис для вас.</p><p>Типы собираемых личных данных . При использовании нашего Сервиса мы просим
                вас
                предоставить нам определенную личную информацию, которая может быть использована для связи или
                идентификации вас.
                </p><p>Личные данные могут включать, но не ограничиваются:</p><p>Адрес электронной почты .Имя и фамилия.
                Номер телефона .Адрес, . почтовый индекс, город и данные пользователя</p><p>Мы также можем собирать информацию о доступе к Сервису и его использовании («Данные об использовании»). Эти данные об использовании включают в себя такую информацию, как адрес

                интернет-протокола вашего компьютера (например, IP-адрес), тип браузера, версия браузера, страницы нашего Сервиса, которые вы посещаете, время и дата вашего посещения, время,
                проведенное на этих страницах, уникальный идентификаторы устройств и другие диагностические данные.
                </p>

                <h2>ТРАКЦИОНИРОВАНИЕ ДАННЫХ И COOKIES</h2>

                <p>Мы используем файлы cookie и аналогичные технологии, чтобы отслеживать действия в нашем Сервисе и
                    хранить определенную информацию.</p><p>Файлы cookie — это файлы с небольшим объемом данных, которые
                могут включать анонимный уникальный идентификатор. Файлы cookie отправляются в ваш браузер с веб-сайта и
                сохраняются на вашем устройстве. Также используются технологии отслеживания, такие как маяки, теги и
                сценарии для сбора и отслеживания информации, а также для улучшения и анализа нашего Сервиса. Вы можете
                указать своему браузеру отказаться от всех файлов cookie или указать, когда файл cookie отправляется.
                Однако, если вы не принимаете файлы cookie, вы не можете использовать некоторые части нашего
                Сервиса.</p><p>Примеры файлов cookie, которые мы используем:</p>
                <ul>
                    <li>Сеансовые файлы cookie. Мы используем сеансовые файлы cookie для работы нашего Сервиса.</li>
                    <li>Файлы cookie предпочтений. Мы используем файлы cookie предпочтений, чтобы запомнить ваши
                        предпочтения и различные настройки.
                    </li>
                    <li>Файлы cookie безопасности. Мы используем файлы cookie безопасности в целях безопасности. </li>
                </ul>
                <p>Использование данных</p><p>NCC
                использует собранные данные для различных целей:</p>
                <ul>
                    <li>Предоставлять и поддерживать Сервис</li>
                    <li>Уведомлять вас об изменениях в нашем Сервисе</li>
                    <li>Разрешить вам участвовать в интерактивных функциях нашего Сервиса, когда вы решите это
                        сделать.
                    </li>
                    <li>Обеспечить обслуживание и поддержку клиентов</li>
                    <li>Для предоставления анализа или ценной информации, чтобы мы могли улучшить Сервис.</li>
                    <li>Для мониторинга использования Сервиса</li>
                    <li>Обнаружение, предотвращение и устранение технических проблем</li>
                </ul>

                <h2>ПЕРЕДАЧА ДАННЫХ</h2>

                <p>Ваша информация, включая Персональные данные, может передаваться и храниться на компьютерах,
                    расположенных за пределами вашей страны или другой государственной юрисдикции, где законы о защите
                    данных могут отличаться от законов вашей юрисдикции.</p><p>Наше согласие с настоящей Политикой Общего регламента по защите данных, сопровождаемое предоставлением вами такой информации, означает ваше согласие на такую передачу.</p>
                <p>NCC предпримет все разумно необходимые шаги для обеспечения безопасного обращения с вашими данными в соответствии с настоящей Политикой общего регулирования защиты данных, и никакая передача ваших личных данных не будет осуществляться организации или стране, если не

                    будут приняты надлежащие меры контроля, включая безопасность ваших данных и другой личной информации.
                </p>

                <h2>РАСКРЫТИЕ ДАННЫХ</h2>

                <p>Правовые требования</p><p>NCC может раскрывать ваши Персональные данные, добросовестно полагая, что такие действия необходимы для:</p>

                <ul>
                    <li>Соблюдения юридических обязательствах</li>
                    <li>Для защиты прав или собственности NCC</li>
                    <li>Для предотвращения или расследования возможных правонарушений, связанных с Сервисом.</li>
                    <li>Для защиты личной безопасности пользователей Сервиса</li>
                    <li>Для защиты от юридической ответственности</li>
                </ul>
                
                <h2>ССЫЛКИ НА ДРУГИЕ САЙТЫ</h2>

                <p>Наш Сервис может содержать ссылки на другие сайты, которыми мы не управляем. Если вы нажмете на
                    стороннюю ссылку, вы будете перенаправлены на сайт этой третьей стороны. Мы
                    настоятельно рекомендуем вам ознакомиться с Общей политикой защиты данных каждого сайта, который вы
                    посещаете.
                </p><p>Мы не контролируем и не несем ответственности за содержание, политику конфиденциальности или действия любых сторонних сайтов или служб.</p>

                <h2>КОНФИДЕНЦИАЛЬНОСТЬ ДЕТЕЙ</h2>

                <p>Наш Сервис не предназначен для лиц моложе 18 лет («Дети»).</p><p>Мы сознательно не собираем личную
                информацию от лиц моложе 18 лет. Если вы являетесь родителем или опекуном и знаете, что ваши Дети
                предоставили нам Персональные данные, свяжитесь с нами. Если нам станет известно, что мы собрали Личные
                данные детей без проверки согласия родителей, мы предпримем шаги для удаления этой информации с наших
                серверов.</p><p>Мы сообщим вам об этом по электронной почте и/или в виде уведомления в нашем Сервисе до
                того, как изменение вступит в силу, и обновим «дату вступления в силу» в верхней части настоящей Общей
                политики защиты данных.</p><p>Вам рекомендуется периодически просматривать настоящую Политику Общего регламента защиты данных на предмет любых изменений. Изменения в настоящей Общей политике защиты данных вступают в силу, когда они публикуются на этой странице.</p>

                <h2>СВЯЗАТЬСЯ С НАМИ</h2>

                <p>Если у вас есть какие-либо вопросы об этой Общей политике защиты данных, свяжитесь с нами:
                    clearncentr@nkc.expert
                </p>
            </div>
        </section>
    );
}

export default DataSecurityScreen;
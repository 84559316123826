import React from 'react';
import './FirstSection.sass'
import LeadForm from "../LeadForm/LeadForm";

function FirstSection(props) {
    return (
        <section id={'firstSection'}>
            <h1>
                Использование искусственного интеллекта в области трейдинга
            </h1>

            <p>Курс по взаимодействию человека и нейросети</p>

            <LeadForm/>

            {/*<button onClick={() => {*/}
            {/*    document.getElementById('leadForm-background').classList.add('leadForm-background-active')*/}
            {/*    document.getElementById('leadForm-popup').classList.add('leadForm-popup-active')*/}
            {/*}}>Записаться*/}
            {/*</button>*/}
        </section>
    );
}

export default FirstSection;